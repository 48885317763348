export function bb64encode(password) {

    var b64encoded = btoa(password);

    var utf8 = unescape(encodeURIComponent(b64encoded));
    var arr2 = [];
    for (var i = 0; i < utf8.length; i++) {
        arr2.push(utf8.charCodeAt(i));
    }
    var a = new Uint8Array(arr2);
    var bb64encoded = btoa(String.fromCharCode.apply(null, a));
    return bb64encoded
}

export function validateName(name) {
    var validate = ""
    var n = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    var s = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?";
    for (var i = 0; i < name.length; i++) {
        if (name[i] in n) {
            validate = 'false'
            break
        }
        else if (s.indexOf(name.charAt(i)) != -1) {
            validate = 'false'
            break
        }
        else {
            validate = "true"
        }
    }
    return validate
}

export function validateEmail(mail) {
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
    return regex.test(mail);
}

export function validatePassword(password) {
    var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    return re.test(password);

}