import React, { useState } from 'react';
import { ButtonGroup, Button, Card } from '@blueprintjs/core';

export default function DataTable(props) {
    const [selectedItemPerPage, setSelectedItemPerPage] = useState(props.itemList);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPagesCount = Math.ceil(parseInt(props.totalItemsCount) / parseInt(selectedItemPerPage));


    const renderPaginationButton = (totalPagesCount, currentPage, onPageChange) => {
        let counter = [];
        if (totalPagesCount > 1) {
            if (!(currentPage == 1)) {

                counter = [<Button variant="contained" size="small" style={{ background: "#fff" }} aria-label="Go to first page" aria-disabled="true" onClick={() => { onPageChange(currentPage - 1); setCurrentPage(currentPage - 1) }}>
                    <h4>&laquo;</h4>
                </Button>];
            }
            for (let i = 1; i <= totalPagesCount; i++) {
                counter.push(<Button variant="contained" size="small" className={currentPage === i ? 'pagination' : ''} color={currentPage === i ? 'primary' : 'default'} onClick={() => { onPageChange(i); setCurrentPage(i) }}>{i}</Button>)
            }
            if (totalPagesCount > 1 && !(currentPage == totalPagesCount)) {
                counter.push(<Button variant="contained" size="small" style={{ background: '#fff' }} aria-label="Go to last page" aria-disabled="true" onClick={() => { onPageChange(currentPage + 1); setCurrentPage(currentPage + 1) }}>
                    <h4>&raquo;</h4>
                </Button>);
            }
        }
        return counter;
    }
    return (
        <div style={{ width: "100%" }}>
            <Card style={{ width: "100%", overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
                <table style={{ width: "100%" }} className="bp3-html-table  bp3-interactive .modifier">
                    <thead>
                        <tr >
                            {
                                props.headers.map((row, index) => {
                                    return (<th key={index}> {row}</th>)
                                })
                            }
                        </tr >
                    </thead>
                    <tbody>
                        {props.body}
                    </tbody>
                </table>
                {totalPagesCount > 1 ? <div style={{ padding: 10, float: "right" }}>
                    <ButtonGroup className="new-pagination">
                        {renderPaginationButton(totalPagesCount, currentPage, props.onPageChange)}
                    </ButtonGroup>
                </div> : null}
            </Card>
        </div >
    );
}
