import React, { Component, createFactory } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Container from '../../layouts/Container'
import { Button, ButtonGroup, Classes, Dialog, FormGroup, InputGroup, Menu, MenuItem, Position, Toast, Toaster, Alert, Intent } from '@blueprintjs/core'
import { getUserSubscriptions } from '../../../library/api'
// import Container from '../../layouts/Container'
import Header from '../../layouts/Header'
import DataTable from '../../../Components/table/DataTable';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'



class UserSubscriptions extends Component {
    state = {
        subscriptionList: [],
        toasts: [],
        showaddon: false,
        addonList: []

    }
    toaster;
    refHandlers = {
        toaster: (ref) => this.toaster = ref,
    };
    _navigateTo(link) {
        this.props.history.push(link)
    }
    componentDidMount() {
        const toaster = this.toaster;
        getUserSubscriptions(this.props.accesstoken, "608415a1ebb88037fbb6a040",
            (success) => {
                // console.log(success.data)
                this.setState({ subscriptionList: success.data })
                // console.log(this.state.subscriptionList)
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });
                // alert(failure.msg)
            }, (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
                // alert(String(error))
            })
    }
    _renderTable = () => {
        const toaster = this.toaster
        return (
            <DataTable

                headers={["Sl No.", "Package Name", "Package Cost", "Currency Unit", "Purchased On", "Expiry Date", "Payment Status", "Total Cost", "Action"]}
                body={this.state.subscriptionList.map((value, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value.package_name}</td>
                        <td>{value.package_cost}</td>
                        <td>{value.package_currency_unit}</td>
                        <td>{value.purchased_on}</td>
                        <td>{value.expiry_date}</td>
                        <td>{value.payment_status}</td>
                        <td>{value.total_cost}</td>

                        <td>
                            <Popover2
                                position={Position.BOTTOM}>
                                <Button icon="add" text="AddOn" intent="primary" onClick={() => this.addOnTable(value)}></Button>
                            </Popover2>
                        </td>
                    </tr>
                ))}
            />
        )
    }

    addOnTable = (value) => {
        console.log(value.addon)
        this.setState({
            showaddon: true,
            addonList: value.addon
        })
    }

    render() {
        return (
            <div>
                <Header>
                    <Toaster {...{ position: 'top-right' }} ref={this.refHandlers.toaster}>
                        {this.state.toasts.map(toast => <Toast {...toast} />)}
                    </Toaster>
                    <Container style={{ paddingBottom: 0 }}>
                        <h2>Subscription List</h2>
                    </Container>
                    <Container style={{ justifyContent: 'center' }}>
                        {this.state.showaddon ?
                            <Dialog
                                canEscapeKeyClose={true}
                                onClose={() => this.setState({ showaddon: false })}
                                title="Add On List"
                                isOpen={this.state.showaddon}
                                style={{width:700}}
                            >

                                <DataTable
                                    headers={["Sl No.","Feature Name", "Cost", "Unit Cost ", "Currency Unit", "Request Map", "Purchase Value", "Purchased On", "Unit Type"]}
                                    body={this.state.addonList.map((value, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{value.feature_name}</td>
                                            <td>{value.cost}</td>
                                            <td>{value.cost_per_unit}</td>
                                            <td>{value.currency_unit}</td>
                                            <td>{value.feature_request_map}</td>
                                            <td>{value.purchase_value}</td>
                                            <td>{value.purchased_on}</td>
                                            <td>{value.unit_type}</td>
                                        </tr>
                                    ))}
                                />

                            </Dialog>
                            : null
                        }

                        {this.state.subscriptionList.length === 0 ? <center style={{ marginTop: 30 }}>No Users registered yet. </center> : this._renderTable()}
                    </Container>
                </Header>

            </div>
        )
    }
}
const mapPropsToState = (store) => {
    return {
        accesstoken: store.authReducer.accesstoken
    }
}
export default withRouter(connect(mapPropsToState)(UserSubscriptions))
