import React, { Component, createFactory } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Container from '../../layouts/Container'
import Header from '../../layouts/Header'
import { Button, ButtonGroup, FormGroup, InputGroup, Dialog, Classes, Position, Menu, MenuItem, Alert, Intent, Toast, Toaster, Icon, Card } from '@blueprintjs/core';
import DataTable from '../../../Components/table/DataTable';
import { getPackageFeature, getFeature, addFeatureToPackage, deletePackageFeature, getPackageStats, featureList } from '../../../library/api'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'

class PackageView extends Component {
    state = {
        features: [],
        open_add_package_feature: false,
        onadd: false,
        show_package_feature_table: true,
        showfeatures: false,
        package_features_id: '',
        package_id: '',
        feature_id: "",
        name: "",
        feature_request_map: "",
        cost_per_unit: '',
        currency_unit: "",
        unit_type: "",
        package_features: [],
        userPage: {
            count: 0
        },
        pageNumber: 1,
        text: "Add",
        icon: "add",
        isDelete: false,
        packageStats: [],
        quantity: '',
    }

    componentDidMount() {

        getPackageFeature(this.props.accesstoken, this.props.match.params.package_id,
            (success) => {

                this.setState({ package_features: success.feature })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(error)

            })

        getPackageStats(this.props.accesstoken, this.props.match.params.package_id,
            (success => {
                this.setState({ packageStats: success.count })
            }),
            (failure) => {
                alert(failure.msg)
            },
            (error) => {
                alert(error)
            })

    }

    addPackageFeature = () => {
        featureList(this.props.accesstoken,
            (success) => {
                this.setState({
                    userPage: success,
                    features: success.data,
                    showfeatures: true,
                })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))
            })
    }

    addFeature = (value, index) => {
        addFeatureToPackage(
            {
                "package_id": this.props.match.params.package_id,
                "feature_id": value.feature_id,
                "allowed_unit": this.state.quantity
            },

            this.props.accesstoken,
            (success) => {

                let features_list = [...this.state.features]
                features_list[index]['selected'] = true
                this.setState({ features: features_list })

                alert(success.msg)
                this.componentDidMount()
            },
            (failure) => {
                alert(failure.msg)

            },
            (error) => {
                alert(String(error))
            })


    }
    deleteFeature = (value) => {
        this.setState({
            isDelete: true,
            package_features_id: value.package_features_id
        })
    }
    handleDeleteCancel = () => {
        this.setState({
            isDelete: false
        })
    }
    _handleDeleteConfirm = () => {
        this.setState({
            isDelete: false
        })
        deletePackageFeature(this.state.package_features_id, this.props.accesstoken,
            (success) => {
                this.componentDidMount()
                alert(success.msg)
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))
            })
    }


    render() {
        console.log("this.state.features", this.state.features)
        return (
            <div>
                <Header>
                    <div style={{ float: 'right', display: 'flex', flexDirection: 'row', padding: 10 }}>
                        <ButtonGroup >
                            <Button onClick={this.addPackageFeature} icon="add" intent="success" style={{ margin: 5, height: 40, width: "150px", fontSize: 16 }} >Add Feature</Button>
                        </ButtonGroup>
                    </div>
                    <div style={{ paddingBottom: 0, paddingTop: 40, paddingLeft: 20 }}>
                        <h2>Package  Features</h2>
                    </div>
                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center', display: 'flex', flexWrap: 'wrap', marginLeft: 20, marginRight: 20, marginTop: 20 }}>
                        <Card style={{ margin: 5, display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <h2 style={{ margin: 0, marginBottom: 10, fontWeight: 'normal' }}>Purchase Times</h2>
                                <Icon icon="comparison" iconSize="50" intent={Intent.PRIMARY} style={{ color: '#000' }} />
                            </div>
                            <h1 style={{ marginLeft: 50, fontSize: 40 }}>{this.state.packageStats}</h1>
                        </Card>
                    </div>
                    {this.state.showfeatures ?
                        <Dialog

                            canEscapeKeyClose={true}
                            onClose={() => this.setState({ showfeatures: false })}
                            title="Add Feature"
                            isOpen={this.state.showfeatures}
                            style={{ justifyContent: "center", width: 800 }}
                            fullWidth={true}
                            maxWidth={'md'}

                        >

                            <table style={{ textAlign: "center", marginTop: 15 }}>
                                <thead>
                                    <th>SI No</th>
                                    <th>Name</th>
                                    <th>Request Map</th>
                                    <th>Cost</th>
                                    <th>Currency Unit</th>
                                    <th>Quantity</th>
                                    <th>Unit Type</th>
                                    <th>Action</th>
                                </thead>
                                <tbody>
                                    {this.state.features.map((value, index) =>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{value.name}</td>
                                            <td>{value.feature_request_map}</td>
                                            <td>{value.cost_per_unit}</td>
                                            <td>{value.currency_unit}</td>
                                            <td><input type="text" onChange={(event) => this.setState({ quantity: event.target.value })} /></td>
                                            <td>{value.unit_type}</td>
                                            <td>

                                                <Button onClick={() => this.addFeature(value, index)}
                                                    icon={"add"} intent="primary" style={{ margin: 5, fontSize: 16 }} >
                                                    {"Add"}</Button>

                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </Dialog>
                        : null}
                    {

                        <Container style={{ justifyContent: "center", textalign: "center" }}>
                            {
                                this.state.package_features.length === 0 && this.state.show_package_feature_table ? <center style={{ marginTop: 30 }}>No packages registered yet. Please create a new package to get started<br />
                                    Please use the Create button on the top right corner</center>
                                    :
                                    <div style={{ justifyContent: 'center', width: 1250 }}>
                                        <table style={{ width: "100%" }} className="bp3-html-table  bp3-interactive .modifier">
                                            <thead>
                                                <tr>
                                                    <th>Sl No.</th>
                                                    <th>Feature Name</th>
                                                    <th>Feature Request Map</th>
                                                    <th>Unit Cost</th>
                                                    <th>Currency Unit</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Type</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            {this.state.package_features != undefined ?
                                                <tbody>

                                                    {this.state.package_features.map((value, index) =>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{value.name}</td>
                                                            <td>{value.feature_request_map}</td>
                                                            <td>{value.cost_per_unit}</td>
                                                            <td>{value.currency_unit}</td>
                                                            <td>{this.state.quantity}</td>
                                                            <td>{value.unit_type}</td>
                                                            <td>
                                                                <Button onClick={() => this.deleteFeature(value)} intent="danger" style={{ margin: 5, fontSize: 16 }} >Delete</Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                :
                                                <tbody />
                                            }
                                            <tfoot>
                                                <tr>
                                                </tr>
                                            </tfoot>
                                        </table>

                                    </div>
                            }
                            <Alert
                                cancelButtonText="Cancel"
                                confirmButtonText="Delete"
                                icon="trash"
                                intent={Intent.DANGER}
                                isOpen={this.state.isDelete}
                                loading={this.state.isLoading}
                                onCancel={this.handleDeleteCancel}
                                onConfirm={this._handleDeleteConfirm}
                            >
                                <p>
                                    Are you sure you want to Delete ?
                                </p>
                            </Alert>
                        </Container>

                    }
                </Header>
            </div>
        )
    }
}
const mapPropsToState = (store) => {
    return {
        accesstoken: store.authReducer.accesstoken
    }
}
export default withRouter(connect(mapPropsToState)(PackageView))