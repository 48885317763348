import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, FormGroup, InputGroup, Dialog, Classes, Position, Menu, MenuItem, Alert, Intent, Toast, Toaster } from '@blueprintjs/core';
import DataTable from '../../../Components/table/DataTable';
import Container from '../../layouts/Container'
import { createBillingPage, getBillingPage, updateBillingPage, deleteBillingPage, searchPackage, packageActivation, getActivePackage, getInActivePackage } from '../../../library/api'
import { withRouter } from 'react-router-dom';
import { packageList } from '../../../library/api';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import Header from '../../layouts/Header'



class Packages extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filterText: "Filter",
            toasts: [],
            isDelete: false,
            isOpen: false,
            packages: [],
            shownewbill: false,
            showupdate: false,
            package_id: "",
            name: '',
            cost: '',
            status: '',
            currency_unit: '',
            validity: '',
            userPage: {
                count: 0
            },
            pageNumber: 1
        }
    }
    toaster;

    refHandlers = {
        toaster: (ref) => this.toaster = ref,
    };

    componentDidMount() {
        packageList(this.props.accesstoken, (success) => {
            this.setState({ packages: success.data })
        },
            (failure) => {
                alert(failure.msg)
            },
            (error) => {
                alert(error)

            })

    }
    createBill = (event) => {
        this.setState({
            shownewbill: true
        })
    }

    _handleOrgDialogClose = () => {
        this.setState({
            shownewbill: false,
            showupdate: false
        })
    }

    _handleConfirm = () => {
        const toaster = this.toaster;

        if (this.state.name.trim() !== "" && this.state.cost !== "" && this.state.currency_unit !== "" && this.state.validity !== "") {
            this.setState({ shownewbill: false })
            createBillingPage(this.state, this.props.accesstoken,
                (success) => {
                    this.componentDidMount()
                    toaster.show({ message: success.msg, intent: 'success' });
                    this.setState({
                        name: '',
                        cost: '',
                        currency_unit: '',
                        validity: '',
                    })
                },
                (failure) => {
                    toaster.show({ message: failure.msg, intent: 'danger' });
                },
                (error) => {
                    toaster.show({ message: String(error), intent: 'danger' });
                })
        }
        else {
            toaster.show({ message: "Input field can not be blank.", intent: 'danger' });
        }
    }

    _renderTable = () => {
        return (
            <DataTable
                totalItemsCount={this.state.userPage.count}
                itemList={this.state.packages.length}

                onPageChange={(pageNumber) => {
                    getBillingPage(pageNumber, this.props.accesstoken,
                        (success) => {
                            alert(success)
                            this.setState({
                                userPage: success,
                                packages: success.data
                            })
                        },
                        (failure) => {
                            alert(failure.msg)

                        }, (error) => {
                            alert(String(error))
                        })
                }}
                headers={["Sl No.", "Package Name", "Cost", "Currency Unit", "Validity", "Action"]}
                body={this.state.packages.map((value, index) => (
                    <tr key={index} style={{ textAlign: "center" }}>
                        <td >{index + 1}</td>
                        <td>{value.name}</td>
                        <td>{value.cost}</td>
                        <td>{value.currency_unit}</td>
                        <td>{value.validity}</td>
                        <td>
                            <Popover2 content={this._renderActionMenu(value)}
                                position={Position.BOTTOM}>
                                <Button icon="cog" text="Action" intent="primary" rightIcon={"caret-down"} />
                            </Popover2>
                        </td>
                    </tr>
                ))}
            />
        )
    }


    _renderActionMenu(value) {
        return (
            <Menu>
                <MenuItem icon="share" text="View" onClick={() => this._navigateTo(`/packageView/${value.package_id}`)} />

                {value.status === "ACTIVE" ?
                    <MenuItem intent="warning" icon="ban-circle" text="Deactive" onClick={() => this.handleMoveOpen(value)} />
                    :
                    <MenuItem intent="success" icon="ban-circle" text="Active" onClick={() => this.handleMoveOpen(value)} />
                }


                <MenuItem intent="success" icon="edit" text="Edit" onClick={() => this.editPackage(value)} />

                <MenuItem intent="danger" icon="trash" text="Delete" onClick={() => this.handleDelete(value)} />
            </Menu>
        )
    }

    editPackage = (value) => {
        this.setState({
            showupdate: true,
            package_id: value.package_id,
            name: value.name,
            cost: parseFloat(value.cost),
            currency_unit: value.currency_unit,
            validity: value.validity,

        })
    }

    handleDelete = (value) => {
        this.setState({
            isDelete: true,
            package_id: value.package_id,
        });
    }

    handleDeleteCancel = () => this.setState({ isDelete: false });
    _navigateTo(link) {

        this.props.history.push(link)
    }

    _handleDeleteConfirm = () => {
        this.setState({
            isDelete: false,
        });
        const toaster = this.toaster;
        deleteBillingPage(this.state.package_id, this.props.accesstoken,
            (success) => {
                toaster.show({ message: success.msg, intent: 'success' });
                this.componentDidMount()
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });

            })
    }

    _handleSearch = () => {

        searchPackage(this.state.search, this.props.accesstoken,
            (success) => {
                this.setState({ packages: success.data })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))

            })
    }


    handleMoveOpen = (value) => {
        if (value.status === "ACTIVE") {
            this.setState({
                isOpen: true,
                package_id: value.package_id,
                status: "IN-ACTIVE"
            })
        }
        else {
            this.setState({
                isOpen: true,
                package_id: value.package_id,
                status: "ACTIVE"
            })
        }
    }
    handleMoveCancel = () => this.setState({ isOpen: false });

    handleMoveActivationConfirm = () => {
        this.setState({
            isOpen: false,
        });
        const toaster = this.toaster;
        packageActivation(this.state.package_id, this.state.status, this.props.accesstoken,
            (success) => {
                toaster.show({ message: this.state.status == 'ACTIVE' ? "Package Activated Successfully" : "Package Deactivated Successfully", intent: 'success' });
                this.componentDidMount()
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    handleActive = () => {
        this.setState({ filterText: "Active Packages" })
        getActivePackage("ACTIVE", this.props.accesstoken,
            (success) => {
                this.setState({
                    packages: success.data,

                })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))
            })


    }
    handleinActive = () => {
        this.setState({ filterText: "InActive Packages" })

        getInActivePackage("IN-ACTIVE", this.props.accesstoken,
            (success) => {
                this.setState({
                    packages: success.data,

                })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))
            })

    }

    allPackage = () => {
        this.setState({ filterText: "All Packages" })

        getBillingPage(this.props.accesstoken,
            (success) => {
                this.setState({
                    packages: success.data
                })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))
            })
    }



    _handleUpdateConfirm = () => {

        const toaster = this.toaster;
        if (this.state.name.trim() !== "" && this.state.cost !== "" && this.state.currency_unit !== "" && this.state.validity !== "") {
            updateBillingPage(this.state.package_id, this.state.name, this.state.cost, this.state.currency_unit, this.state.validity, this.props.accesstoken, (success) => {
                toaster.show({ message: success.msg, intent: 'success' });
                this.componentDidMount()
                this.setState({
                    showupdate: false,
                })
            },
                (failure) => {
                    toaster.show({ message: failure.msg, intent: 'danger' });
                },
                (error) => {
                    toaster.show({ message: String(error), intent: 'danger' });
                })
        }
        else {
            toaster.show({ message: "Input field can not be blank.", intent: 'danger' });
        }

    }

    render() {

        return (
            <Header>
                <Toaster {...{ position: 'top-right' }} ref={this.refHandlers.toaster}>
                    {this.state.toasts.map(toast => <Toast {...toast} />)}
                </Toaster>
                <div className='row' style={{ paddingInline: 10, paddingTop: 10, display: "flex", justifyContent: "space-between" }}>
                    <div className='col-md-2'>
                        <Menu style={{ fontSize: 17 }}>

                            <MenuItem icon="filter-list" text={this.state.filterText} style={{ fontWeight: "bold" }}>

                                <MenuItem
                                    onClick={this.allPackage} intent="primary"
                                    text="All Packages" />
                                <MenuItem
                                    onClick={this.handleActive} intent="success"
                                    text="Active" />
                                <MenuItem
                                    onClick={this.handleinActive} intent="danger"
                                    text="In Active" />

                            </MenuItem>
                        </Menu>
                    </div>
                    <div className='col-md-5 row justify-content-center' >
                        <FormGroup className='col-sm' >
                            <div class="bp3-input-group bp3-large">
                                <span class="bp3-icon bp3-icon-search"></span>
                                <input class="bp3-input" type="search" placeholder="Search Admin" dir="auto" onChange={(event) =>
                                    this.setState({ search: event.target.value })} />
                            </div>
                        </FormGroup>
                        <Button className='col-sm' intent="primary" onClick={this._handleSearch} type="submit" style={{ margin: 15, marginLeft: 0, marginTop: 0, padding: 0, paddingTop: 0, paddingBottom: 0, width: "150px", fontSize: 16 }}>Search</Button>
                        <Button className='col-sm' onClick={this.createBill} icon="add" intent="success" style={{ margin: 15, marginLeft: 0, marginTop: 0, padding: 0, paddingTop: 0, paddingBottom: 0, width: "150px", fontSize: 16 }} >Create</Button>
                    </div>
                </div>


                <div style={{ paddingBottom: 0, paddingLeft: 20 }}>
                    <h2>Package </h2>
                </div>
                {this.state.showupdate ?

                    <div className="container card" style={{ width: "500px", backgroundColor: "rgb(158, 158, 253)", padding: 0 }}>
                        <Dialog
                            canEscapeKeyClose={true}
                            onClose={() => this.setState({ showupdate: false })}
                            title="Update Package"
                            isOpen={this.state.showupdate}
                        >
                            <div className={Classes.DIALOG_BODY}>

                                <FormGroup
                                    helperText="Package Name "
                                    label="Name"
                                    labelFor="person-name"
                                    labelInfo="(required)"
                                >
                                    <InputGroup onChange={(event) => this.setState({ name: event.target.value })} id="name" required placeholder="Ex : Gold" value={this.state.name} />
                                </FormGroup>

                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    <FormGroup
                                        helperText="Cost"
                                        label="Cost"

                                        labelFor="org_cost"
                                        labelInfo="(required)"
                                        style={{ marginRight: 10 }}
                                    >
                                        <InputGroup id="org-cost" onChange={(event) => this.setState({ cost: event.target.value })} type="number" required placeholder="200" value={this.state.cost} leftIcon="dollar" />
                                    </FormGroup>
                                    <FormGroup
                                        helperText="Select Currency Unit"
                                        label="Currency Unit"
                                        labelInfo="(required)"
                                        style={{ marginRight: 10 }}
                                    >
                                        <div className="bp3-select bp3-fill .modifier">
                                            <select onChange={(event) => {
                                                this.setState({ currency_unit: event.target.value })
                                            }} required
                                                value={this.state.currency_unit} >
                                                <option selected>Choose an item...</option>
                                                {[['INR', 'INR'], ['USD', 'USD'], ['EUR', 'EURO']].map(value => <option key={value[1]} value={value[1]}>{value[0]}  </option>)}
                                            </select>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormGroup
                                        helperText="Validity in Days"
                                        label="Validity"
                                        labelFor="person_Validity"
                                        labelInfo="(required)"
                                        style={{ marginRight: 10 }}

                                    >
                                        <InputGroup onChange={(event) => this.setState({ validity: event.target.value })} leftIcon="calendar"
                                            type="number" id="person_validity" required placeholder="365 Days" value={this.state.validity} />
                                    </FormGroup>

                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <Tooltip2 content="Close organisation Bill Creation">
                                            <Button onClick={this._handleOrgDialogClose}>Close</Button>
                                        </Tooltip2>
                                        <Tooltip2 content="Click here to Save the Bill">
                                            <Button intent="primary" onClick={this._handleUpdateConfirm}>Update</Button>
                                        </Tooltip2>

                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>

                    : null}
                {
                    this.state.shownewbill ?
                        <div >

                            <div
                                className="container card" style={{ width: "500px", backgroundColor: "rgb(158, 158, 253)", padding: 0 }}>
                                <Dialog
                                    canEscapeKeyClose={true}
                                    onClose={() => this.setState({ shownewbill: false })}
                                    title="Create new Package"
                                    isOpen={this.state.shownewbill}
                                >

                                    <div className={Classes.DIALOG_BODY} >

                                        <FormGroup
                                            helperText="Package Name "
                                            label="Name"
                                            labelFor="person-name"
                                            labelInfo="(required)"
                                        >
                                            <InputGroup onChange={(event) => this.setState({ name: event.target.value })} id="name" required placeholder="Ex : Gold" />
                                        </FormGroup>

                                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                                            <FormGroup
                                                helperText="Cost"
                                                label="Cost"

                                                labelFor="org_cost"
                                                labelInfo="(required)"
                                                style={{ marginRight: 10 }}
                                            >
                                                <InputGroup id="org-cost" onChange={(event) => this.setState({ cost: event.target.value })} type="number" required placeholder="200" leftIcon="dollar" />
                                            </FormGroup>
                                            <FormGroup
                                                helperText="Select Currency Unit"
                                                label="Currency Unit"
                                                labelInfo="(required)"
                                                style={{ marginRight: 10 }}
                                            >
                                                <div className="bp3-select bp3-fill .modifier">
                                                    <select onChange={(event) => {
                                                        this.setState({ currency_unit: event.target.value })
                                                    }} required
                                                        value={this.state.currency_unit}
                                                    >
                                                        <option selected>Choose an item...</option>
                                                        {[['INR', 'INR'], ['USD', 'USD'], ['EUR', 'EURO']].map(value => <option key={value[1]} value={value[1]}>{value[0]}  </option>)}
                                                    </select>
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <FormGroup
                                                helperText="Validity in Days"
                                                label="Validity"
                                                labelFor="person_Validity"
                                                labelInfo="(required)"
                                                style={{ marginRight: 10 }}

                                            >
                                                <InputGroup onChange={(event) => this.setState({ validity: event.target.value })} leftIcon="calendar"
                                                    type="number" id="person_validity" required placeholder="365 Days" />
                                            </FormGroup>

                                        </div>
                                        <div className={Classes.DIALOG_FOOTER}>
                                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                                <Tooltip2 content="Close organisation Bill Creation">
                                                    <Button onClick={this._handleOrgDialogClose}>Cancel</Button>
                                                </Tooltip2>
                                                <Tooltip2 content="Click here to Save the Bill">
                                                    <Button intent="primary" onClick={this._handleConfirm}>Confirm</Button>
                                                </Tooltip2>

                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                            </div>
                        </div>

                        : null
                }
                <Container style={{ justifyContent: "center", textalign: "center" }}>
                    {
                        this.state.packages.length === 0 ? <center style={{ marginTop: 30 }}>No packages registered yet. Please create a new package to get started<br />
                            Please use the Create button on the top right corner</center>
                            : this._renderTable()
                    }

                    <Alert
                        className=""
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={this.state.isDelete}
                        onCancel={this.handleDeleteCancel}
                        onConfirm={this._handleDeleteConfirm}
                    >
                        <p>
                            Are you sure you want to Delete ?
                        </p>
                    </Alert>
                    {this.state.status === "IN-ACTIVE" ?
                        <Alert
                            className=""
                            cancelButtonText="Cancel"

                            confirmButtonText="Deactive"
                            icon="ban-circle"
                            intent={Intent.WARNING}
                            isOpen={this.state.isOpen}
                            loading={this.state.isLoading}
                            onCancel={this.handleMoveCancel}
                            onConfirm={this.handleMoveActivationConfirm}
                        >
                            <p>
                                Are you sure you want to Deactive ?
                            </p>
                        </Alert>
                        :
                        <Alert
                            className=""
                            cancelButtonText="Cancel"

                            confirmButtonText="Active"
                            icon="ban-circle"
                            intent={Intent.SUCCESS}
                            isOpen={this.state.isOpen}
                            loading={this.state.isLoading}
                            onCancel={this.handleMoveCancel}
                            onConfirm={this.handleMoveActivationConfirm}
                        >
                            <p>
                                Are you sure you want to Active ?
                            </p>
                        </Alert>
                    }




                </Container>



            </Header>
        )
    }
}

const mapPropsToState = (store) => {

    return {
        accesstoken: store.authReducer.accesstoken
    }
}

export default withRouter(connect(mapPropsToState)(Packages))