import { Button, ButtonGroup, Classes, Dialog, FormGroup, InputGroup, Menu, MenuItem, Position, Toast, Toaster, Alert, Intent, Card } from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createOrganisation, getOrganisations, updateOrganisation, deleteOrganisation, blockOrganisation } from '../../../library/api'
import Container from '../../layouts/Container'
import Header from '../../layouts/Header'
import DataTable from '../../../Components/table/DataTable';
import { validateEmail, validateName } from '../../../library/utils'

class Organisations extends Component {
    state = {
        toasts: [],
        orgs: [],
        isOpen: false,
        isDelete: false,
        openCreateOrgDialog: false,
        openEditOrgDialog: false,
        org_id: '',
        org_name: '',
        org_logo_path: '',
        org_phone: '',
        org_email: '',
        org_type: '',
        org_desc: '',
        org_website: '',
        org_block: false,
        isUnblock: false,
        userPage: {
            count: 0
        },
        pageNumber: 1
    }
    toaster;
    refHandlers = {
        toaster: (ref) => this.toaster = ref,
    };

    componentDidMount() {
        const toaster = this.toaster
        getOrganisations(this.state.pageNumber, this.props.accesstoken,
            (success) => {
                this.setState({ orgs: success.data })
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            }, (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    _renderActionMenu(value) {
        return (
            <Menu>
                <MenuItem icon="share" text="View" onClick={() => this._navigateTo(`/orgView/${value.org_id}`)} />
                <MenuItem intent="success" icon="edit" text="Edit" onClick={() => this.editOrg(value)} />
                {value.org_block === false ?
                    <MenuItem intent="warning" icon="ban-circle" text="Block" onClick={() => this.handleMoveOpen(value)} />
                    :
                    <MenuItem intent="warning" icon="ban-circle" text="Unblock" onClick={() => this.handleMoveOpen(value)} />
                }
                <MenuItem intent="danger" icon="trash" text="Delete" onClick={() => this.handleMoveDelete(value)} />
            </Menu>
        )
    }

    _renderCreateOrganisationDialog() {
        return (
            <Dialog
                canEscapeKeyClose={true}
                onClose={() => this.setState({ openCreateOrgDialog: false })}
                title="Create new Organisation"
                isOpen={this.state.openCreateOrgDialog}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div>
                        <FormGroup
                            helperText="Name of the organisation"
                            label="Organisation name"
                            labelFor="org-name"
                            labelInfo="(required)"
                        >
                            <InputGroup onChange={(event) => this.setState({ org_name: event.target.value })} required leftIcon="office" id="org-name" placeholder="Epsum Labs" />
                        </FormGroup>
                        <FormGroup
                            helperText="Logo path of organisation"
                            label="Logo path"
                            labelFor="org-logo"
                            labelInfo="(required)"
                        >
                            <InputGroup leftIcon="bring-data" id="org-logo" onChange={(event) => this.setState({ org_logo_path: event.target.value })} required placeholder="https://www.epsumthings.com/things.png" />
                        </FormGroup>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FormGroup
                                helperText="Contact number"
                                label="Phone number"
                                labelFor="org-phone"
                                labelInfo="(required)"
                                style={{ marginRight: 10 }}
                            >
                                <InputGroup leftIcon="phone" id="org-phone" onChange={(event) => this.setState({ org_phone: event.target.value })} required type="number" placeholder="+91xxxxxxxxxx" />
                            </FormGroup>
                            <FormGroup
                                helperText="Email address"
                                label="Email"
                                labelFor="org-email"
                                labelInfo="(required)"
                            >
                                <InputGroup leftIcon="envelope" id="org-email" type="email" onChange={(event) => this.setState({ org_email: event.target.value })} required placeholder="developers@epsumlabs.com" />
                            </FormGroup>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FormGroup
                                helperText="Select organisation type"
                                label="Organisation type"

                                labelInfo="(required)"
                                style={{ marginRight: 10 }}
                            >
                                <div className="bp3-select bp3-fill .modifier">
                                    <select required onChange={(event) => {
                                        this.setState({ org_type: event.target.value })
                                    }}>
                                        <option selected>Choose an item...</option>
                                        {[['Manufacturing', 'manufacturing'], ['Service', 'service'], ['Logistics', 'logistics'], ['Others', 'others']].map(value => <option key={value[1]} value={value[1]}>{value[0]}</option>)}
                                    </select>
                                </div>
                            </FormGroup>
                            <FormGroup
                                helperText="Organisation website"
                                label="Website"
                                labelFor="org-website"
                                labelInfo="(required)"
                            >
                                <InputGroup onChange={(event) => this.setState({ org_website: event.target.value })} required leftIcon="globe" id="org-website" placeholder="www.epsumlabs.com" />
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip2 content="Close organisation creation dialog">
                            <Button onClick={this._handleOrgDialogClose}>Close</Button>
                        </Tooltip2>
                        <Tooltip2 content="Click here to register the Organisation">
                            <Button intent="primary" onClick={this._handleRegister}>Register</Button>
                        </Tooltip2>

                    </div>
                </div>
            </Dialog>
        )
    }

    _handleOrgDialogClose = () => {
        this.setState({
            openCreateOrgDialog: false,
            openEditOrgDialog: false
        })
    }

    _handleRegister = () => {
        const toaster = this.toaster;
        if (this.state.org_name.trim() !== "" && this.state.org_logo_path.trim() !== "" && this.state.org_phone !== "" && this.state.org_email !== "" && this.state.org_type !== "" && this.state.org_website.trim() !== "") {
            var nameValid = validateName(this.state.org_name)
            var emailValid = validateEmail(this.state.org_email)
            if (nameValid == "false") {
                toaster.show({ message: "Name contains only Alphabets", intent: 'danger' });
            }
            else if (emailValid == false) {
                toaster.show({ message: "Email Is not formated", intent: 'danger' });
            }
            else if (this.state.org_phone.length <= 9) {
                toaster.show({ message: "Enter Correct Phone number", intent: 'danger' });
            }
            else {
                createOrganisation(this.state, this.props.accesstoken,
                    (success) => {
                        toaster.show({ message: success.msg, intent: 'success' });
                        this.setState({ openCreateOrgDialog: false, org_name: "", org_logo_path: "", org_phone: "", org_email: "", org_type: "", org_website: "" })
                        this.componentDidMount()
                    },
                    (failure) => {
                        toaster.show({ message: failure.msg, intent: 'danger' });
                    },
                    (error) => {
                        toaster.show({ message: String(error), intent: 'danger' });
                    })
            }
        }
        else {
            toaster.show({ message: "Field Can't Be Blank", intent: 'danger' });
        }
    }

    createOrg = () => {
        this.setState({ openCreateOrgDialog: true })
    }
    editOrg = (value) => {
        this.setState({
            openEditOrgDialog: true,
            org_id: value.org_id,
            org_name: value.org_name,
            org_logo_path: value.org_logo_path,
            org_phone: value.org_phone,
            org_email: value.org_email,
            org_type: value.org_type,
            org_desc: value.org_desc,
            org_website: value.org_website
        })

    }
    _handleUpdate = () => {

        const toaster = this.toaster;
        if (this.state.org_name.trim() !== "" && this.state.org_logo_path.trim() !== "" && this.state.org_phone !== "" && this.state.org_email !== "" && this.state.org_type !== "" && this.state.org_website.trim() !== "") {
            this.setState({
                openEditOrgDialog: false,
            })
            updateOrganisation(this.state.org_id, this.state.org_name, this.state.org_logo_path,
                this.state.org_phone, this.state.org_email, this.state.org_type, this.state.org_desc, this.state.org_website
                , this.props.accesstoken,
                (success) => {
                    toaster.show({ message: success.msg, intent: 'success' });
                    this.componentDidMount()
                },
                (failure) => {
                    toaster.show({ message: failure.msg, intent: 'danger' });
                },
                (error) => {
                    toaster.show({ message: String(error), intent: 'danger' });
                })
        }
        else {
            toaster.show({ message: "Field Can't Be Blank", intent: 'danger' });
        }
    }

    handleMoveOpen = (value) => {
        if (value.org_block == false) {
            this.setState({
                isOpen: true,
                org_id: value.org_id,
                org_block: true
            });
        }
        else {
            this.setState({
                isUnblock: true,
                org_id: value.org_id,
                org_block: false
            });
        }
    }

    handleMoveCancel = () => this.setState({ isOpen: false });

    handleMoveBlockConfirm = () => {
        this.setState({
            isOpen: false,
            isUnblock: false
        });
        const toaster = this.toaster;
        blockOrganisation(this.state.org_id, this.state.org_block, this.props.accesstoken,
            (success) => {
                toaster.show({ message: success.msg, intent: 'success' });
                this.componentDidMount()
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });
            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }
    handleMoveDelete = (value) => {
        this.setState({
            isDelete: true,
            org_id: value.org_id,
        });
    }
    handleMoveConfirm = () => {
        this.setState({
            isDelete: false,
        });
        const toaster = this.toaster;
        deleteOrganisation(this.state.org_id, this.props.accesstoken,
            (success) => {
                toaster.show({ message: success.msg, intent: 'success' });
                this.componentDidMount()
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });
            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    handleMoveDeleteCancel = () => this.setState({ isDelete: false });
    _navigateTo(link) {
        this.props.history.push(link)
    }

    _renderTable = () => {
        const toaster = this.toaster
        return (
            <DataTable
                totalItemsCount={this.state.userPage.count}
                itemList={this.state.orgs.length}
                onPageChange={(pageNumber) => {
                    getOrganisations(pageNumber, this.props.accesstoken,
                        (success) => {
                            this.setState({
                                userPage: success,
                                orgs: success.data
                            })
                        },
                        (failure) => {
                            toaster.show({ message: failure.msg, intent: 'danger' });

                        }, (error) => {
                            toaster.show({ message: String(error), intent: 'danger' });
                        })
                }}
                headers={["Sl No.", "Organisation", "Date of Registration", "Action"]}
                body={this.state.orgs.map((value, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value.org_name}</td>
                        <td>{value.org_created_on}</td>
                        <td>
                            <Popover2 content={this._renderActionMenu(value)}
                                position={Position.BOTTOM}>
                                <Button icon="cog" text="Action" intent="primary" rightIcon={"caret-down"} />
                            </Popover2>
                        </td>
                    </tr>
                ))}
            />
        )
    }



    render() {
        return (
            <Header>
                <Toaster {...{ position: 'top-right' }} ref={this.refHandlers.toaster}>
                    {this.state.toasts.map(toast => <Toast {...toast} />)}
                </Toaster>
                {this._renderCreateOrganisationDialog()}
                <div style={{ width: '100%', padding: 10, paddingBottom: 30 }}>
                    <div style={{ float: 'right' }}>
                        <Button onClick={this.createOrg} icon="add" intent="success" >Create Organisation</Button>
                    </div>
                </div>
                <h2 className='m-3'>Organisations</h2>
                <div style={{ justifyContent: 'center', marginInline: "2%" }}>
                    {this.state.orgs.length === 0 ? <center style={{ marginTop: 30 }}>No organisations registered yet. Please create a new organisation to get started<br />
                        Please use the Create button on the top right corner</center> : this._renderTable()}
                    <Dialog
                        canEscapeKeyClose={true}
                        onClose={() => this.setState({ openEditOrgDialog: false })}
                        title="Edit Organisation"
                        isOpen={this.state.openEditOrgDialog}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <div>
                                <FormGroup
                                    helperText="Name of the organisation"
                                    label="Organisation name"
                                    labelFor="org-name"
                                    labelInfo="(required)"
                                >
                                    <InputGroup onChange={(event) => this.setState({ org_name: event.target.value })} required leftIcon="office" id="org-name" placeholder="Epsum Labs" defaultValue={this.state.org_name} />

                                </FormGroup>

                                <FormGroup
                                    helperText="Logo path of organisation"
                                    label="Logo path"
                                    labelFor="org-logo"
                                    labelInfo="(required)"
                                >
                                    <InputGroup leftIcon="bring-data" id="org-logo" onChange={(event) => this.setState({ org_logo_path: event.target.value })} required placeholder="https://www.epsumthings.com/things.png"
                                        defaultValue={this.state.org_logo_path}
                                    />
                                </FormGroup>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormGroup
                                        helperText="Contact number"
                                        label="Phone number"
                                        labelFor="org-phone"
                                        labelInfo="(required)"
                                        style={{ marginRight: 10 }}
                                    >
                                        <InputGroup leftIcon="phone" id="org-phone" onChange={(event) => this.setState({ org_phone: event.target.value })} required type="number" placeholder="+91xxxxxxxxxx"
                                            defaultValue={this.state.org_phone}

                                        />
                                    </FormGroup>

                                    <FormGroup
                                        helperText="Email address"
                                        label="Email"
                                        labelFor="org-email"
                                        labelInfo="(required)"
                                    >
                                        <InputGroup leftIcon="envelope" id="org-email" type="email" onChange={(event) => this.setState({ org_email: event.target.value })} required placeholder="developers@epsumlabs.com"
                                            defaultValue={this.state.org_email}

                                        />
                                    </FormGroup>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormGroup
                                        helperText="Select organisation type"
                                        label="Organisation type"

                                        labelInfo="(required)"
                                        style={{ marginRight: 10 }}
                                    >
                                        <div className="bp3-select bp3-fill .modifier">
                                            <select required onChange={(event) => {
                                                this.setState({ org_type: event.target.value })
                                            }}>
                                                <option selected value={this.state.org_type}>{this.state.org_type}</option>
                                                {[['Manufacturing', 'manufacturing'], ['Service', 'service'], ['Logistics', 'logistics'], ['Others', 'others']].map(value => <option key={value[1]} value={value[1]}>{value[0]}</option>)}
                                            </select>
                                        </div>
                                    </FormGroup>

                                    <FormGroup
                                        helperText="Organisation website"
                                        label="Website"
                                        labelFor="org-website"
                                        labelInfo="(required)"
                                    >
                                        <InputGroup onChange={(event) => this.setState({ org_website: event.target.value })} required leftIcon="globe" id="org-website" placeholder="www.epsumlabs.com"
                                            defaultValue={this.state.org_website}
                                        />
                                    </FormGroup>
                                </div>
                            </div>


                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip2 content="Close organisation creation dialog">
                                    <Button onClick={this._handleOrgDialogClose}>Close</Button>
                                </Tooltip2>
                                <Tooltip2 content="Click here to update the Organisation">
                                    <Button intent="primary" onClick={this._handleUpdate}>Update</Button>
                                </Tooltip2>

                            </div>
                        </div>
                    </Dialog>
                    <Alert
                        className=""
                        cancelButtonText="Cancel"
                        confirmButtonText="Block"
                        icon="ban-circle"
                        intent={Intent.WARNING}
                        isOpen={this.state.isOpen}
                        loading={this.state.isLoading}
                        onCancel={this.handleMoveCancel}
                        onConfirm={this.handleMoveBlockConfirm}
                    >
                        <p>
                            Are you sure you want to Block ?
                        </p>
                    </Alert>
                    <Alert
                        className=""
                        cancelButtonText="Cancel"
                        confirmButtonText="UnBlock"
                        icon="ban-circle"
                        intent={Intent.WARNING}
                        isOpen={this.state.isUnblock}
                        loading={this.state.isLoading}
                        onCancel={this.handleMoveCancel}
                        onConfirm={this.handleMoveBlockConfirm}
                    >
                        <p>
                            Are you sure you want to UnBlock ?
                        </p>
                    </Alert>

                    <Alert
                        className=""
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={this.state.isDelete}
                        loading={this.state.isLoading}
                        onCancel={this.handleMoveDeleteCancel}
                        onConfirm={this.handleMoveConfirm}
                    >
                        <p>
                            Are you sure you want to Delete ?
                        </p>
                    </Alert>
                </div>
            </Header >
        )
    }
}

const mapPropsToState = (store) => {

    return {
        accesstoken: store.authReducer.accesstoken
    }
}

export default withRouter(connect(mapPropsToState)(Organisations))
