import React, { Component } from 'react'

export default class Container extends Component {
    render() {
        return (
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',padding:this.props.padding||20,...this.props.style}}>
                {this.props.children}
            </div>
        )
    }
}
