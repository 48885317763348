import { Button, ButtonGroup, Classes, Dialog, FormGroup, InputGroup, Menu, MenuItem, Position, Toast, Toaster, Alert, Intent, Card, Elevation, Slider, Switch, Icon, IconSize } from '@blueprintjs/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Container from '../../layouts/Container'
import Header from '../../layouts/Header'
import { getOrganisationDetails, createOrgUser, getAdminOrganisationUser, getUsersOrgList } from '../../../library/api'
import { Tooltip2 } from '@blueprintjs/popover2'
import timezone from '../../../library/timezones'
import { country } from '../../../library/country.js';
import DataTable from '../../../Components/table/DataTable';

import { bb64encod } from "../../../library/security";
import { validateEmail, validateName, validatePassword } from '../../../library/utils'

class ViewOrganisation extends Component {
    state = {
        toasts: [],
        orgs: [],
        orgUser: [],
        openCreateUserDialog: false,
        name: '',
        email: '',
        phone: '',
        reg_country: '',
        reg_timezone: '',
        password: '',
        userPage: '',
        currentPage: 1,
        pageNumber: 1,
        userPage: {
            count: 0
        },
    }
    toaster;
    refHandlers = {
        toaster: (ref) => this.toaster = ref,
    };

    componentDidMount() {
        const toaster = this.toaster
        getOrganisationDetails(this.props.accesstoken, this.props.match.params.id,
            (success) => {
                this.setState({ orgs: success.data, ...success.data })
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            }, (error) => {
                toaster.show({ message: String(error), intent: 'danger' });

            })
        getUsersOrgList(this.props.match.params.id, this.state.pageNumber, this.props.accesstoken,
            (success) => {
                this.setState({
                    userPage: success,
                    orgUser: success.data
                })
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            }, (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }



    _renderCreateUserDialog() {
        return (
            <Dialog
                canEscapeKeyClose={true}
                onClose={() => this.setState({ openCreateUserDialog: false })}
                title="Create Admin"
                isOpen={this.state.openCreateUserDialog}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div>
                        <FormGroup
                            label="Full Name"
                            labelFor="name"
                            labelInfo="(required)"
                        >
                            <InputGroup onChange={(event) => this.setState({ name: event.target.value })} required leftIcon="office" id="name" placeholder="Abcd" />
                        </FormGroup>

                        <FormGroup
                            label="Email"
                            labelFor="email"
                            labelInfo="(required)"
                        >
                            <InputGroup leftIcon="envelope" id="email" type="email" onChange={(event) => this.setState({ email: event.target.value })} required placeholder="abc@gmail.com" />
                        </FormGroup>
                        <FormGroup
                            label="Phone number"
                            labelFor="phone"
                            labelInfo="(required)"
                            style={{ marginRight: 10 }}
                        >
                            <InputGroup leftIcon="phone" id="phone" onChange={(event) => this.setState({ phone: event.target.value })} required type="number" placeholder="+91xxxxxxxxxx" />
                        </FormGroup>
                        <FormGroup
                            label="Time Zone"
                            labelFor="Time Zone"
                            labelInfo="(required)"
                            style={{ marginRight: 10 }}
                        >
                            <select style={{ width: '100%', borderColor: '#ccc', height: 35 }}
                                type="text"
                                className="input_box"
                                onChange={event => {
                                    this.setState({
                                        reg_timezone: event.target.value
                                    });
                                }}
                                required
                            >
                                <option value="">Select Timezone</option>
                                {timezone.map((timezone, index) => {
                                    return <option value={timezone} key={index}>{timezone}</option>;
                                })}
                            </select>
                        </FormGroup>
                        <FormGroup
                            label="Country"
                            labelFor="Country"
                            labelInfo="(required)"
                            style={{ marginRight: 10 }}
                        >
                            <select style={{ width: '100%', borderColor: '#ccc', height: 35 }}
                                type="text"
                                className="input_box"
                                onChange={event => {
                                    this.setState({
                                        reg_country: event.target.value
                                    });
                                }}
                                required
                            >
                                <option value="">Select Country</option>
                                {country.map((country, index) => {
                                    return <option value={country.name} key={index}>{country.name}</option>;
                                })}
                            </select>
                        </FormGroup>
                        <FormGroup
                            label="Password"
                            labelFor="password"
                            labelInfo="(required)"
                        >
                            <InputGroup leftIcon="envelope" id="password" type="password" onChange={(event) => this.setState({ password: event.target.value })} required placeholder="password" />
                        </FormGroup>
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip2 content="Close organisation creation dialog">
                            <Button onClick={this._handleUserDialogClose}>Close</Button>
                        </Tooltip2>
                        <Tooltip2 content="Click here to create Admin">
                            <Button intent="primary" onClick={this._handleCreate}>Create</Button>
                        </Tooltip2>
                    </div>
                </div>
            </Dialog>
        )
    }
    createUser = () => {
        this.setState({ openCreateUserDialog: true })
    }
    _handleUserDialogClose = () => {
        this.setState({ openCreateUserDialog: false })
    }
    _handleCreate = () => {
        const data = {
            "org_id": this.props.match.params.id,
            "name": this.state.name,
            "email": this.state.email,
            "phone": this.state.phone,
            "timezone": this.state.reg_timezone,
            "country": this.state.reg_country,
            "password": Buffer.from(this.state.password).toString('base64'),
        }

        const toaster = this.toaster;
        if (this.state.name.trim() !== "" && this.state.email !== "" && this.state.phone !== "" && this.state.password !== "" && this.state.reg_timezone !== "" && this.state.reg_country !== "") {
            var nameValid = validateName(this.state.name)
            var emailValid = validateEmail(this.state.email)
            var passwordValid = validatePassword(this.state.password)
            if (nameValid == "false") {
                toaster.show({ message: "Name contains only Alphabets", intent: 'danger' });
            }
            else if (emailValid == false) {
                toaster.show({ message: "Email Is not formated", intent: 'danger' });
            }
            else if (this.state.phone.length <= 9) {
                toaster.show({ message: "Enter Correct Phone number", intent: 'danger' });
            }
            else if (passwordValid == false) {
                toaster.show({ message: "Password must contain at least 8 characters, including UPPER/lowercase , numbers and Special character .", intent: 'danger' });
            }
            else {
                this.setState({ openCreateUserDialog: false })
                createOrgUser(this.props.accesstoken, data,
                    (success) => {
                        console.log("success", success)
                        toaster.show({ message: "Admin Created Successfully ", intent: 'success' });
                        this.componentDidMount()
                        this.setState({ name: "", email: "", phone: "", password: "", timezone: "", country: "" })
                    },
                    (failure) => {
                        console.log("failure", failure)
                        toaster.show({ message: failure.msg, intent: 'danger' });
                    },
                    (error) => {
                        console.log("error", error)
                        toaster.show({ message: String(error), intent: 'danger' });
                    })
            }
        }
        else {
            toaster.show({ message: "Field Can't Be Blank", intent: 'danger' });
        }
    }

    getPageCount = () => {
        return Math.ceil(parseInt(this.state.userPage.count) / parseInt(10));
    }

    handlePageChange(pageIndex) {
        let pageNumber = pageIndex === 0 ? 1 : pageIndex;
        this.setState({ currentPage: pageNumber });
    }

    _renderTable = () => {
        const toaster = this.toaster
        return (
            <DataTable
                totalItemsCount={this.state.userPage.count}
                itemList={this.state.orgUser.length}

                onPageChange={(pageNumber) => {
                    getUsersOrgList(this.props.match.params.id, pageNumber, this.props.accesstoken,
                        (success) => {
                            this.setState({
                                userPage: success,
                                orgUser: success.data
                            })
                        },
                        (failure) => {
                            toaster.show({ message: failure.msg, intent: 'danger' });

                        }, (error) => {
                            toaster.show({ message: String(error), intent: 'danger' });
                        })
                }}
                headers={["Sl No.", "Name", "Email", "Phone", "Country", "Timezone", "Last Active"]}
                body={this.state.orgUser.map((value, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value.name}</td>
                        <td>{value.email}</td>
                        <td>{value.phone}</td>
                        <td>{value.country}</td>
                        <td>{value.timezone}</td>
                        <td>{value.last_active} </td>

                    </tr>
                ))}
            />
        )
    }


    render() {
        return (
            <Header>
                <Toaster {...{ position: 'top-right' }} ref={this.refHandlers.toaster}>
                    {this.state.toasts.map(toast => <Toast {...toast} />)}
                </Toaster>
                {this._renderCreateUserDialog()}
                <div style={{ width: '100%', padding: 10 }}>
                    <div style={{ float: 'right' }}>
                        <ButtonGroup>
                            <Button onClick={this.createUser} icon="add" intent="success" >Create Admin</Button>
                        </ButtonGroup>
                    </div>
                </div>
                <Container style={{ paddingBottom: 0 }}>
                    <h2>{this.state.org_name}</h2>
                </Container>
                {this.state.orgs != [] && this.state.orgs.org_status != undefined ?
                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center', display: 'flex', flexWrap: 'wrap', marginLeft: 20, marginRight: 20 }}>
                        <Card style={{ margin: 5, display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <h2 style={{ margin: 0, marginBottom: 10, fontWeight: 'normal' }}>Things</h2>
                                <Icon icon="comparison" iconSize="50" intent={Intent.PRIMARY} style={{ color: '#000' }} />
                            </div>
                            <h1 style={{ marginLeft: 50, fontSize: 40 }}>{this.state.orgs.org_status.active_things}</h1>
                        </Card>
                        <Card style={{ margin: 5, display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <h2 style={{ margin: 0, marginBottom: 10, fontWeight: 'normal' }}>Camera</h2>
                                <Icon icon="camera" iconSize="50" intent={Intent.PRIMARY} style={{ color: '#000' }} />
                            </div>
                            <h1 style={{ marginLeft: 50, fontSize: 40 }}>{this.state.orgs.org_status.active_camera}</h1>
                        </Card>
                        <Card style={{ margin: 5, display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <h2 style={{ margin: 0, marginBottom: 10, fontWeight: 'normal' }}>Apps</h2>
                                <Icon icon="menu" iconSize="50" intent={Intent.PRIMARY} style={{ color: '#000' }} />
                            </div>
                            <h1 style={{ marginLeft: 50, fontSize: 40 }}>{this.state.orgs.org_status.active_application}</h1>
                        </Card>
                        <Card style={{ margin: 5, display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <h2 style={{ margin: 0, marginBottom: 10, fontWeight: 'normal' }}>Users</h2>
                                <Icon icon="user" iconSize="50" intent={Intent.PRIMARY} style={{ color: '#000' }} />
                            </div>
                            <h1 style={{ marginLeft: 50, fontSize: 40 }}>{this.state.orgs.org_status.active_user}</h1>

                        </Card>
                        <Card style={{ margin: 5, display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <h2 style={{ margin: 0, marginBottom: 10, fontWeight: 'normal' }}>Trends</h2>
                                <Icon icon="trending-up" iconSize="50" intent={Intent.PRIMARY} style={{ color: '#000' }} />
                            </div>
                            <h1 style={{ marginLeft: 50, fontSize: 40 }}>{this.state.orgs.org_status.active_IrBlaster}</h1>
                        </Card>
                        <Card style={{ margin: 5, display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <h2 style={{ margin: 0, marginBottom: 10, fontWeight: 'normal' }}>Projects</h2>
                                <Icon icon="projects" iconSize="50" intent={Intent.PRIMARY} style={{ color: '#000' }} />
                            </div>
                            <h1 style={{ marginLeft: 50, fontSize: 40 }}>{this.state.orgs.org_status.active_project}</h1>
                        </Card>
                    </div>
                    : <div />
                }

                <Container style={{ justifyContent: 'center', marginRight: '10%', marginLeft: '10%' }}>
                    {this.state.orgUser.length === 0 ? <center style={{ marginTop: 30 }}>No Users registered.<br />
                        Please use the Create User button on the top right corner to create a new user for the organisation</center> : this._renderTable()}
                </Container>
            </Header >
        )
    }
}

const mapPropsToState = (store) => {
    return {
        accesstoken: store.authReducer.accesstoken
    }
}

export default withRouter(connect(mapPropsToState)(ViewOrganisation))
