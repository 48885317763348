import React, {
    lazy,
    Suspense
} from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Organisations from '../organisations/components/Organisations';
import ViewOrganisation from '../organisations/components/ViewOrganisation';
import Information from '../informations/components/Information';
import Profile from '../dashboard/components/Profile';
import Admin from '../admin/components/Admin';
import Users from '../users/components/Users';
import Packages from '../billing/components/Packages';
import Feature from '../billing/components/Feature';
import PackageView from '../billing/components/PackageView';
import UserSubscriptions from '../users/components/UserSubscriptions';

const Login = lazy(() => import('../authentication/components/Login'));
const Dashboard = lazy(() => import('../dashboard/components/Dashboard'));


export default function Navigation(props) {
    return (
        <Router >
            <Suspense fallback={<div style={{ display: 'flex', marginTop: "10%", alignItems: 'center', justifyContent: "center" }}>
                <div >Please wait while the page loads.</div>
            </div>}>
                <Switch>
                    <Route exact path="/">
                        <Login />
                    </Route>

                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route exact path="/dashboard">
                        <Dashboard />
                    </Route>

                    <Route exact path="/org">
                        <Organisations />
                    </Route>
                    <Route exact path="/orgView/:id">
                        <ViewOrganisation />
                    </Route>
                    <Route exact path="/info">
                        <Information />
                    </Route>
                    <Route exact path="/profile">
                        <Profile />
                    </Route>
                    <Route exact path="/admin">
                        <Admin />
                    </Route>
                    <Route exact path="/users">
                        <Users />
                    </Route>
                    <Route exact path="/packages">
                        <Packages />
                    </Route>
                    <Route exact path="/feature">
                        <Feature />
                    </Route>
                    <Route exact path="/packageView/:package_id">
                        <PackageView />
                    </Route>
                    <Route exact path="/userSubscriptions/:user_id">
                        <UserSubscriptions />
                    </Route>
                </Switch>
            </Suspense>
        </Router>
    )
}