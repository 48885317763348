import { Button, ButtonGroup, Classes, Dialog, FormGroup, InputGroup, Menu, MenuItem, Position, Toast, Toaster, Alert, Intent, Card, TextArea, Icon, IconSize, Checkbox, Switch, Label } from '@blueprintjs/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Container from '../../layouts/Container'
import Header from '../../layouts/Header'
import { getInformations, createInformation, deleteInformation } from '../../../library/api'
import { IconNames } from "@blueprintjs/icons";



class Information extends Component {
    state = {
        toasts: [],
        infos: [],
        information: '', isDelete: false, deletedValue: {}
    }

    toaster;

    refHandlers = {
        toaster: (ref) => this.toaster = ref,
    };

    componentDidMount() {
        const toaster = this.toaster
        getInformations(this.props.accesstoken,
            (success) => {
                this.setState({ infos: success.data })
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            }, (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    createInfo = () => {
        console.log(this.state.information)
        const toaster = this.toaster
        if (this.state.information.trim() !== '') {
            createInformation(this.props.accesstoken, this.state.information,
                (success) => {
                    toaster.show({ message: success.msg, intent: 'success' });
                    this.componentDidMount()
                    this.setState({
                        information: ''
                    })
                },
                (failure) => {
                    toaster.show({ message: failure.msg, intent: 'danger' });
                },
                (error) => {
                    toaster.show({ message: String(error), intent: 'danger' });
                })
        }
        else {
            toaster.show({ message: "Input field can not be blank. ", intent: "danger" })
        }
    }

    deleteInfo = (value) => {
        const toaster = this.toaster;
        deleteInformation(this.props.accesstoken, value.info_id,
            (success) => {
                toaster.show({ message: success.msg, intent: 'success' });
                this.componentDidMount()
                this.setState({ isDelete: false })
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });
            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }


    render() {
        return (
            <Header>
                <Toaster {...{ position: 'top-right' }} ref={this.refHandlers.toaster}>
                    {this.state.toasts.map(toast => <Toast {...toast} />)}
                </Toaster>

                <Container style={{ paddingBottom: 0, }}>
                    <h2>Information</h2>
                    <Switch style={{ margin: 50, marginTop: 23, marginBottom: 23 }} large={true} defaultChecked={true} />
                    <div style={{ width: "90%", marginLeft: "5%", marginBlock: "3%" }}>
                        <Card style={{ width: "100%" }} interactive={true}>
                            <TextArea
                                style={{ width: "100%", minHeight: 100 }}
                                growVertically={true}
                                large={true}
                                intent={Intent.PRIMARY}
                                onChange={(event) => this.setState({ information: event.target.value })}
                                value={this.state.information}
                            />
                            <Button className='mt-2' rightIcon="arrow-right" intent="success" text="Submit" onClick={() => this.createInfo()} />

                        </Card>
                    </div>
                    <div style={{ width: "90%", marginLeft: "5%" }}>
                        <Card interactive={true} style={{ justifyContent: "center", width: "100%", overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
                            <table className="bp3-html-table bp3-html-table-striped bp3-interactive .modifier" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>Sl No.</th>
                                        <th>Information</th>
                                        <th>Created On</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>

                                {this.state.infos != undefined ?
                                    <tbody>
                                        {this.state.infos.map((value, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{value.notification}</td>
                                                <td>{value.created_on}</td>
                                                <td>
                                                    <Button icon="trash" text="Delete" intent="danger" onClick={() => this.setState({ deletedValue: value, isDelete: true })} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    :
                                    <tbody />
                                }


                                <tfoot>
                                    <tr>

                                    </tr>
                                </tfoot>
                            </table>
                        </Card>
                    </div>
                    <Alert
                        className=""
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={this.state.isDelete}
                        onCancel={() => this.setState({ isDelete: false })}
                        onConfirm={() => this.deleteInfo(this.state.deletedValue)}
                    >
                        <p>
                            Are you sure you want to Delete ?
                        </p>
                    </Alert>
                </Container>


            </Header >
        )
    }
}


const mapPropsToState = (store) => {

    return {
        accesstoken: store.authReducer.accesstoken
    }
}

export default withRouter(connect(mapPropsToState)(Information))
