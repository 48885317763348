import { Button, Elevation, Toast, Toaster, Card, Icon } from '@blueprintjs/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Container from '../../layouts/Container'
import Header from '../../layouts/Header'
import { getProfileDetails, updateProfileDetails, changePassword } from '../../../library/api'
import { validateName, validatePassword } from '../../../library/utils'

class Profile extends Component {
    state = {
        toasts: [],
        profile: {},
        information: '',
        name: '',
        phone: '',
        oldpassword: '',
        newpassword: '',
        confpassword: '',
        eye1: 'eye-off',
        type1: "password",
        eye2: 'eye-off',
        type2: "password",
        eye3: 'eye-off',
        type3: "password"
    }
    toaster;
    refHandlers = {
        toaster: (ref) => this.toaster = ref,
    };
    componentDidMount() {
        const toaster = this.toaster
        getProfileDetails(this.props.accesstoken,
            (success) => {
                this.setState({
                    profile: success.data,
                    name: success.data.name,
                    phone: success.data.phone,
                })
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            }, (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    _handleUpdate = () => {
        const toaster = this.toaster;
        if (this.state.name !== "" && this.state.phone !== "") {
            var nameValid = validateName(this.state.name)
            if (nameValid == "false") {
                toaster.show({ message: "Name contains only Alphabets", intent: 'danger' });
            }
            else if (this.state.phone.length <= 9) {
                toaster.show({ message: "Enter Correct Phone number", intent: 'danger' });
            }
            else {
                updateProfileDetails(this.state.name, this.state.phone, this.props.accesstoken,
                    (success) => {
                        toaster.show({ message: success.msg, intent: 'success' });
                        this.componentDidMount()
                    },
                    (failure) => {
                        toaster.show({ message: failure.msg, intent: 'danger' });
                    },
                    (error) => {
                        toaster.show({ message: String(error), intent: 'danger' });
                    })
            }
        }
        else {
            toaster.show({ message: "Field Can't Be Blank", intent: 'danger' });
        }
    }
    _handleUpdatePassword = () => {
        const toaster = this.toaster;
        var passwordValid = validatePassword(this.state.newpassword)
        if (passwordValid == false) {
            toaster.show({ message: "Password must contain at least 8 characters, including UPPER/lowercase , numbers and Special character .", intent: 'danger' });
        }
        else if (this.state.newpassword != this.state.confpassword) {
            toaster.show({ message: "New Password & Confirm Password Not Same", intent: 'danger' });
        }
        else {
            changePassword(this.state.oldpassword, this.state.newpassword, this.props.accesstoken,
                (success) => {
                    toaster.show({ message: " Password Changed Successfully ", intent: 'success' });
                    this.setState({
                        oldpassword: '',
                        newpassword: '',
                        confpassword: ''
                    })
                    this.componentDidMount()
                },
                (failure) => {
                    toaster.show({ message: failure.msg, intent: 'danger' });
                },
                (error) => {
                    toaster.show({ message: String(error), intent: 'danger' });
                })
        }
    }

    render() {
        return (
            <Header>
                <Toaster {...{ position: 'top-right' }} ref={this.refHandlers.toaster}>
                    {this.state.toasts.map(toast => <Toast {...toast} />)}
                </Toaster>
                <div className='m-3 row mt-4' style={{ paddingBottom: 0, flexDirection: "row", justifyContent: "space-evenly", marginInlineStart: "3%" }}>
                    <Card className='col-sm-3 m-2' interactive={true} style={{ textAlign: 'center', marginInlineStart: "5%" }}>
                        <img src="images/avatar7.png" style={{ width: 150, margin: 0, borderRadius: '50%' }} />
                        <h4>{this.state.profile.name}</h4>
                        <hr style={{ color: '#ccc', marginTop: 30, marginBottom: 30 }} />
                        <div>
                            <input style={{ color: '#6c757d', margin: 0, fontSize: 14, fontWeight: 500, boxShadow: "none", marginBottom: 10, borderBottomWidth: 1, borderBottomColor: '#ccc', width: "90%" }}
                                class="bp3-input .modifier password_field" type={this.state.type1} dir="auto"
                                placeholder="Old Password" value={this.state.oldpassword}
                                onChange={(event) => this.setState({ oldpassword: event.target.value })}
                            />
                            <span onClick={() => this.setState({ type1: this.state.type1 == "password" ? "text" : "password", eye1: this.state.eye1 == "eye-off" ? "eye-open" : "eye-off" })} style={{ width: "10%", marginLeft: -20 }}>
                                <Icon icon={this.state.eye1} size={'20'}></Icon>
                            </span>
                        </div>
                        <div>
                            <input style={{ color: '#6c757d', margin: 0, fontSize: 14, fontWeight: 500, boxShadow: "none", marginBottom: 10, borderBottomWidth: 1, borderBottomColor: '#ccc', width: "90%" }}
                                class="bp3-input .modifier password_field" type={this.state.type2} dir="auto"
                                placeholder="New Password" value={this.state.newpassword}
                                onChange={(event) => this.setState({ newpassword: event.target.value })}
                            />
                            <span onClick={() => this.setState({ type2: this.state.type2 == "password" ? "text" : "password", eye2: this.state.eye2 == "eye-off" ? "eye-open" : "eye-off" })} style={{ width: "10%", marginLeft: -20 }}>
                                <Icon icon={this.state.eye2} size={'20'}></Icon>
                            </span>
                        </div>
                        <div>
                            <input style={{ color: '#6c757d', margin: 0, fontSize: 14, fontWeight: 500, boxShadow: "none", marginBottom: 10, borderBottomWidth: 1, borderBottomColor: '#ccc', width: "90%" }}
                                class="bp3-input .modifier password_field" type={this.state.type3} dir="auto"
                                placeholder="Confirm Password" value={this.state.confpassword}
                                onChange={(event) => this.setState({ confpassword: event.target.value })}
                            />
                            <span onClick={() => this.setState({ type3: this.state.type3 == "password" ? "text" : "password", eye3: this.state.eye3 == "eye-off" ? "eye-open" : "eye-off" })} style={{ width: "10%", marginLeft: -20 }}>
                                <Icon icon={this.state.eye3} size={'20'}></Icon>
                            </span>
                        </div>
                        <Button intent="success" text="Change Password" style={{ marginTop: 20 }} onClick={this._handleUpdatePassword} />

                    </Card>
                    <Card className='col-sm-8 m-2' interactive={true} style={{ justifyContent: "center" }} >
                        <div className="row p-4" style={{ marginTop: "10%" }}>
                            <div className="namedetails" style={profileRowStyle}>
                                <div style={{ width: '27%', textAlign: 'left' }}>
                                    <h6 style={{ marginBlock: "6%" }}>Full Name</h6>
                                </div>
                                <div>
                                    {/* <h6 style={{ color: '#6c757d', margin: 0, fontSize: 14, fontWeight: 500 }}> {this.state.profile.name}</h6> */}
                                    <input style={profileInputStyle} class="bp3-input .modifier" type="text" dir="auto" value={this.state.name}
                                        onChange={(event) => this.setState({ name: event.target.value })}

                                    />
                                </div>
                            </div>
                            <div className="namedetails" style={profileRowStyle}>
                                <div style={{ width: '27%', textAlign: 'left' }}>
                                    <h6 style={{ marginBlock: "6%" }}>Email</h6>
                                </div>
                                <div>
                                    {/* <h6 style={{ color: '#6c757d', margin: 0, fontSize: 14, fontWeight: 500 }}> {this.state.profile.email}</h6> */}
                                    <input style={profileInputStyle} class="bp3-input .modifier" type="text" dir="auto" value={this.state.profile.email} />

                                </div>
                            </div>
                            <div className="namedetails" style={profileRowStyle}>
                                <div style={{ width: '27%', textAlign: 'left' }}>
                                    <h6 style={{ marginBlock: "6%" }}>Phone</h6>
                                </div>
                                <div>
                                    {/* <h6 style={{ color: '#6c757d', margin: 0, fontSize: 14, fontWeight: 500 }}> {this.state.profile.phone}</h6> */}
                                    <input style={profileInputStyle} class="bp3-input .modifier" type="text" dir="auto" value={this.state.phone}
                                        onChange={(event) => this.setState({ phone: event.target.value })}
                                    />

                                </div>
                            </div>
                            <div className="namedetails" style={profileRowStyle}>
                                <div style={{ width: '27%', textAlign: 'left' }}>
                                    <h6 style={{ marginBlock: "6%" }}>Date Of Joining</h6>
                                </div>
                                <div>
                                    {/* <h6 style={{ color: '#6c757d', margin: 0, fontSize: 14, fontWeight: 500 }}>{this.state.profile.date_of_joining} </h6> */}
                                    <input style={profileInputStyle} class="bp3-input .modifier" type="text" dir="auto" value={this.state.profile.date_of_joining} />

                                </div>
                            </div>
                            {/* <div className="namedetails" style={profileRowStyle}>
                                <div style={{ width: '27%', textAlign: 'left' }}>
                                    <h6>Address</h6>
                                </div>
                                <div>
                                    <h6 style={{ color: '#6c757d', margin: 0, fontSize: 14, fontWeight: 500 }}> Bay Area, San Francisco, CA</h6>
                                </div>
                            </div> */}
                        </div>
                        <Button intent="success" text="Update Profile" style={{ margin: 20, }} onClick={this._handleUpdate} />

                    </Card>
                </div >
            </Header >
        )
    }
}
const profileRowStyle = { display: 'flex', borderBottom: " 1px solid #ccc", flexDirection: 'row', alignItems: 'center' };
const profileInputStyle = { color: '#6c757d', margin: 0, fontSize: 14, fontWeight: 500, boxShadow: "none" };

const mapPropsToState = (store) => {

    return {
        accesstoken: store.authReducer.accesstoken
    }
}

export default withRouter(connect(mapPropsToState)(Profile))
