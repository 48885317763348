import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, FormGroup, InputGroup, Dialog, Classes, Position, Menu, MenuItem, Alert, Intent, Toast, Toaster, Card, Icon } from '@blueprintjs/core';
import DataTable from '../../../Components/table/DataTable';
import Container from '../../layouts/Container'
import { createFeature, getFeature, updateFeature, deleteFeature, featureList, searchFeature, getFeatureStats, getActiveFeature, getInActiveFeature, featureActivation } from '../../../library/api'
import { withRouter } from 'react-router-dom';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import Header from '../../layouts/Header'

class Feature extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterText: "Filter",
            isDelete: false,
            features: [],
            shownewfeature: false,
            showupdatefeature: false,
            search: '',
            status: '',
            feature_id: "",
            name: '',
            feature_request_map: '',
            cost_per_unit: '',
            currency_unit: '',
            unit_type: '',
            userPage: {
                count: 0
            },
            pageNumber: 1,
            openViews: false,
            featureStats: [],
            isOpen: false,
            toasts: []
        }
    }

    toaster;

    refHandlers = {
        toaster: (ref) => this.toaster = ref,
    };

    componentDidMount() {
        featureList(this.props.accesstoken, (success) => {
            this.setState({ features: success.data })
        },
            (failure) => {
                alert(failure.msg)
            },
            (error) => {
                alert(error)
            })
    }

    createFeature = (event) => {
        this.setState({
            shownewfeature: true
        })
    }

    _handleConfirm = () => {
        const toaster = this.toaster;
        if (this.state.name.trim() !== "" && this.state.feature_request_map.trim() !== "" && this.state.cost_per_unit !== "" && this.state.currency_unit !== "" && this.state.unit_type !== "") {
            this.setState({ shownewfeature: false })
            createFeature(this.state, this.props.accesstoken,
                (success) => {
                    this.componentDidMount()
                    toaster.show({ message: success.msg, intent: 'success' });
                    this.setState({
                        name: '',
                        feature_request_map: '',
                        cost_per_unit: '',
                        currency_unit: '',
                        unit_type: '',
                    })
                },
                (failure) => {
                    toaster.show({ message: failure.msg, intent: 'danger' });

                },
                (error) => {
                    toaster.show({ message: String(error), intent: 'danger' });
                })
        }
        else {
            toaster.show({ message: "Input field can not be blank.", intent: 'danger' });
        }
    }

    _handleClose = () => {
        this.setState({
            shownewfeature: false,
            showupdatefeature: false
        })
    }

    _renderTable = () => {
        return (
            <DataTable
                totalItemsCount={this.state.userPage.count}
                itemList={this.state.features.length}

                onPageChange={(pageNumber) => {
                    getFeature(pageNumber, this.props.accesstoken,
                        (success) => {
                            this.setState({
                                userPage: success,
                                features: success.data
                            })
                        },
                        (failure) => {
                            alert(failure.msg)

                        }, (error) => {
                            alert(String(error))
                        })
                }}
                headers={["Sl No.", "Feature Name", "Request Map", "Cost Per Unit", "Currency Unit", "Unit Type", "Action"]}
                body={this.state.features.length > 0 && this.state.features.map((value, index) => (
                    <tr key={index} style={{ textAlign: "center" }}>
                        <td >{index + 1}</td>
                        <td >{value.name}</td>
                        <td>{value.feature_request_map}</td>
                        <td>{value.cost_per_unit}</td>
                        <td>{value.currency_unit}</td>
                        <td>{value.unit_type}</td>
                        <td>
                            <Popover2 content={this._renderActionMenu(value)}
                                position={Position.BOTTOM}>
                                <Button icon="cog" text="Action" intent="primary" rightIcon={"caret-down"} />
                            </Popover2>
                        </td>
                    </tr>
                ))}
            />
        )
    }

    editFeature = (value) => {
        this.setState({
            showupdatefeature: true,
            feature_id: value.feature_id,
            name: value.name,
            feature_request_map: value.feature_request_map,
            cost_per_unit: value.cost_per_unit,
            currency_unit: value.currency_unit,
            unit_type: value.unit_type,
        })
    }

    openFeatureDetails = (value) => {
        this.setState({
            openViews: true
        })
        getFeatureStats(this.props.accesstoken, value.feature_id,
            (success => {
                this.setState({ featureStats: success.count })
            }),
            (failure) => {
                alert(failure.msg)
            },
            (error) => {
                alert(error)
            })
    }

    handleDelete = (value) => {
        this.setState({
            isDelete: true,
            feature_id: value.feature_id,
        });
    }

    handleDeleteCancel = () => this.setState({ isDelete: false });

    _handleDeleteConfirm = () => {
        const toaster = this.toaster;
        this.setState({
            isDelete: false,
        });
        deleteFeature(this.state.feature_id, this.props.accesstoken,
            (success) => {
                this.componentDidMount()
                toaster.show({ message: success.msg, intent: 'success' });
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });
            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    _renderActionMenu(value) {
        return (
            <Menu>
                <MenuItem intent="success" icon="share" text="View" onClick={() => this.openFeatureDetails(value)} />
                {value.status === "ACTIVE" ?
                    <MenuItem intent="warning" icon="ban-circle" text="Deactive" onClick={() => this.handleMoveOpen(value)} />

                    :

                    <MenuItem intent="primary" icon="ban-circle" text="Active" onClick={() => this.handleMoveOpen(value)} />

                }

                <MenuItem intent="success" icon="edit" text="Edit" onClick={() => this.editFeature(value)} />

                <MenuItem intent="danger" icon="trash" text="Delete" onClick={() => this.handleDelete(value)} />


            </Menu>
        )
    }

    handleMoveOpen = (value) => {
        console.log(value)

        if (value.status === "ACTIVE") {
            this.setState({
                isOpen: true,
                feature_id: value.feature_id,
                status: "IN-ACTIVE"
            })
        }
        else {
            this.setState({
                isOpen: true,
                feature_id: value.feature_id,
                status: "ACTIVE"
            })
        }
    }

    handleMoveCancel = () => this.setState({ isOpen: false });

    handleMoveActivationConfirm = () => {
        const toaster = this.toaster;
        featureActivation(this.state.feature_id, this.state.status, this.props.accesstoken,
            (success) => {
                toaster.show({ message: this.state.status == 'ACTIVE' ? "Feature Activated Successfully" : "Feature Deactivated Successfully", intent: 'success' });
                this.setState({
                    isOpen: false,
                });
                this.componentDidMount()
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    handleActive = () => {
        this.setState({ filterText: "Active Features" })
        getActiveFeature("ACTIVE", this.props.accesstoken,
            (success) => {
                console.log(success)
                this.setState({
                    features: success.data,
                })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))
            })


    }
    handleinActive = () => {
        this.setState({ filterText: "InActive Features" })
        getInActiveFeature("IN-ACTIVE", this.props.accesstoken,
            (success) => {
                console.log(success)
                this.setState({
                    features: success.data,
                })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))
            })

    }

    allFeatures = () => {
        this.setState({ filterText: "All Features" })
        featureList(this.props.accesstoken, (success) => {
            this.setState({ features: success.data })
        },
            (failure) => {
                alert(failure.msg)
            },
            (error) => {
                alert(error)
            })
    }

    _handleUpdateConfirm = () => {
        const toaster = this.toaster;
        if (this.state.name.trim() !== "" && this.state.feature_request_map !== "" && this.state.cost_per_unit !== "" && this.state.currency_unit !== "" && this.state.unit_type !== "") {
            updateFeature(this.state.feature_id, this.state.name, this.state.feature_request_map, this.state.cost_per_unit, this.state.currency_unit, this.state.unit_type, this.props.accesstoken, (success) => {
                toaster.show({ message: success.msg, intent: 'success' });
                this.setState({
                    showupdatefeature: false,
                })
                this.componentDidMount()
            },
                (failure) => {
                    toaster.show({ message: failure.msg, intent: 'danger' });
                },
                (error) => {
                    toaster.show({ message: String(error), intent: 'danger' });
                })
        }
        else {
            toaster.show({ message: "Input field can not be blank.", intent: 'danger' });
        }
    }

    _handleSearch = () => {

        searchFeature(this.state.search, this.props.accesstoken,
            (success) => {
                this.setState({ features: success.data })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))

            })
    }


    render() {
        return (
            <Header>
                <Toaster {...{ position: 'top-right' }} ref={this.refHandlers.toaster}>
                    {this.state.toasts.map(toast => <Toast {...toast} />)}
                </Toaster>
                <div className='row' style={{ paddingInline: 10, paddingTop: 10, display: "flex", justifyContent: "space-between" }}>
                    <div className='col-md-2'> <Menu style={{ fontSize: 17 }}>
                        <MenuItem icon="filter-list" text={this.state.filterText} style={{ fontWeight: "bold" }}>
                            <MenuItem
                                onClick={this.allFeatures} intent="primary"
                                text="All Features" />
                            <MenuItem
                                onClick={this.handleActive} intent="success"
                                text="Active" />
                            <MenuItem
                                onClick={this.handleinActive} intent="danger"
                                text="In Active" />
                        </MenuItem>
                    </Menu></div>

                    <div className='col-md-5 row justify-content-center' >
                        <FormGroup className='col-sm' >
                            <div class="bp3-input-group bp3-large">
                                <span class="bp3-icon bp3-icon-search"></span>
                                <input class="bp3-input" type="search" placeholder="Search Admin" dir="auto" onChange={(event) =>
                                    this.setState({ search: event.target.value })} />
                            </div>
                        </FormGroup>
                        <Button className='col-sm' intent="primary" onClick={this._handleSearch} type="submit" style={{ margin: 15, marginLeft: 0, marginTop: 0, padding: 0, paddingTop: 0, paddingBottom: 0, width: "150px", fontSize: 16 }}>Search</Button>
                        <Button className='col-sm' onClick={this.createFeature} icon="add" intent="success" style={{ margin: 15, marginLeft: 0, marginTop: 0, padding: 0, paddingTop: 0, paddingBottom: 0, width: "150px", fontSize: 16 }} >Create</Button>
                    </div>
                </div>

                <div style={{ paddingBottom: 0, paddingTop: 40, paddingLeft: 20 }}>
                    <h2>Feature </h2>
                </div>
                {this.state.showupdatefeature ?

                    <div
                        className="container card" style={{ width: "500px", backgroundColor: "rgb(158, 158, 253)", padding: 0 }}>
                        <Dialog
                            canEscapeKeyClose={true}
                            onClose={() => this.setState({ showupdatefeature: false })}
                            title="Update Feature"
                            isOpen={this.state.showupdatefeature}
                        >

                            <div className={Classes.DIALOG_BODY} >

                                <FormGroup
                                    helperText="Feature Name "
                                    label="Name"
                                    labelFor="Feature-name"
                                    labelInfo="(required)"
                                >
                                    <InputGroup onChange={(event) => this.setState({ name: event.target.value })} id="name" required placeholder="Ex : Light" value={this.state.name} />
                                </FormGroup>

                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    <FormGroup
                                        helperText="Feature Request Map "
                                        label="Feature Request Map"
                                        labelFor="Feature Request Map"
                                        labelInfo="(required)"
                                        style={{ marginRight: 10 }}

                                    >
                                        <InputGroup onChange={(event) => this.setState({ feature_request_map: event.target.value })} required placeholder="Ex : Demo" value={this.state.feature_request_map} />
                                    </FormGroup>
                                    <FormGroup
                                        helperText="Cost Per Unit"
                                        label="Cost"
                                        labelFor="cost_per_unit"
                                        labelInfo="(required)"
                                        style={{ marginRight: 10 }}
                                    >
                                        <InputGroup onChange={(event) => this.setState({ cost_per_unit: event.target.value })} type="number" required placeholder="200" value={this.state.cost_per_unit} leftIcon="dollar" />
                                    </FormGroup>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormGroup
                                        helperText="Select Currency Unit"
                                        label="Currency Unit"
                                        labelInfo="(required)"
                                        style={{ marginRight: 10 }}
                                    >
                                        <div className="bp3-select bp3-fill .modifier">
                                            <select onChange={(event) => {
                                                this.setState({ currency_unit: event.target.value })
                                            }} required >
                                                <option selected>Choose an item...</option>
                                                {[['INR', 'INR'], ['USD', 'USD'], ['EUR', 'EUR']].map(value => <option key={value[1]} value={value[1]}>{value[0]}  </option>)}
                                            </select>
                                        </div>
                                    </FormGroup>
                                    <FormGroup
                                        helperText="Unit Type"
                                        label="Unit"
                                        labelFor="Unit Type"
                                        labelInfo="(required)"
                                        style={{ marginRight: 10 }}

                                    >
                                        <InputGroup onChange={(event) => this.setState({ unit_type: event.target.value })}
                                            required placeholder=" Gb " value={this.state.unit_type} />
                                    </FormGroup>

                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <Tooltip2 content="Close organisation Bill Creation">
                                            <Button onClick={this._handleClose}>Cancel</Button>
                                        </Tooltip2>
                                        <Tooltip2 content="Click here to Save the Bill">
                                            <Button intent="primary" onClick={this._handleUpdateConfirm}>Update</Button>
                                        </Tooltip2>

                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>


                    : null}
                {
                    this.state.shownewfeature ?

                        <div
                            className="container card" style={{ width: "500px", backgroundColor: "rgb(158, 158, 253)", padding: 0 }}>
                            <Dialog
                                canEscapeKeyClose={true}
                                onClose={() => this.setState({ shownewfeature: false })}
                                title="Create new Feature"
                                isOpen={this.state.shownewfeature}
                            >

                                <div className={Classes.DIALOG_BODY} >

                                    <FormGroup
                                        helperText="Feature Name "
                                        label="Name"
                                        labelFor="Feature-name"
                                        labelInfo="(required)"
                                    >
                                        <InputGroup onChange={(event) => this.setState({ name: event.target.value })} id="name" required placeholder="Ex : Light" />
                                    </FormGroup>

                                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                                        <FormGroup
                                            helperText="Feature Request Map "
                                            label="Feature Request Map"
                                            labelFor="Feature Request Map"
                                            labelInfo="(required)"
                                            style={{ marginRight: 10 }}

                                        >
                                            <InputGroup onChange={(event) => this.setState({ feature_request_map: event.target.value })} required placeholder="Ex : Demo" />
                                        </FormGroup>
                                        <FormGroup
                                            helperText="Cost Per Unit"
                                            label="Cost"
                                            labelFor="cost_per_unit"
                                            labelInfo="(required)"
                                            style={{ marginRight: 10 }}
                                        >
                                            <InputGroup onChange={(event) => this.setState({ cost_per_unit: event.target.value })} type="number" required placeholder="200" leftIcon="dollar" />
                                        </FormGroup>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <FormGroup
                                            helperText="Select Currency Unit"
                                            label="Currency Unit"
                                            labelInfo="(required)"
                                            style={{ marginRight: 10 }}
                                        >
                                            <div className="bp3-select bp3-fill .modifier">
                                                <select onChange={(event) => {
                                                    this.setState({ currency_unit: event.target.value })
                                                }} required >
                                                    <option selected>Choose an item...</option>
                                                    {[['INR', 'INR'], ['USD', 'USD'], ['EUR', 'EUR']].map(value => <option key={value[1]} value={value[1]}>{value[0]}  </option>)}
                                                </select>
                                            </div>
                                        </FormGroup>
                                        <FormGroup
                                            helperText="Unit Type"
                                            label="Unit"
                                            labelFor="Unit Type"
                                            labelInfo="(required)"
                                            style={{ marginRight: 10 }}

                                        >
                                            <InputGroup onChange={(event) => this.setState({ unit_type: event.target.value })}
                                                required placeholder=" Gb " />
                                        </FormGroup>

                                    </div>
                                    <div className={Classes.DIALOG_FOOTER}>
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            <Tooltip2 content="Close organisation Bill Creation">
                                                <Button onClick={this._handleClose}>Cancel</Button>
                                            </Tooltip2>
                                            <Tooltip2 content="Click here to Save the Bill">
                                                <Button intent="primary" onClick={this._handleConfirm}>Confirm</Button>
                                            </Tooltip2>

                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        </div>


                        : null
                }
                <Container style={{ justifyContent: "center", textalign: "center" }}>
                    {
                        this.state.features.length === 0 ? <center style={{ marginTop: 30 }}>No features registered yet. Please create a new feature to get started<br />
                            Please use the Create button on the top right corner</center>
                            :
                            this._renderTable()
                    }
                    {
                        this.state.openViews ?

                            <Dialog
                                canEscapeKeyClose={true}
                                onClose={() => this.setState({ openViews: false })}
                                title="Feature Details"
                                isOpen={this.openFeatureDetails}
                            >
                                <div style={{ flexDirection: 'row', justifyContent: 'center', textAlign: 'start', display: 'flex', flexWrap: 'wrap', marginLeft: 20, marginRight: 20, marginTop: 20 }}>
                                    <Card style={{ margin: 5, display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                                        <div>
                                            <h2 style={{ margin: 0, marginBottom: 10, fontWeight: 'normal' }}>Purchase Times</h2>
                                            <Icon icon="comparison" iconSize="50" intent={Intent.PRIMARY} style={{ color: '#000' }} />
                                        </div>
                                        <h1 style={{ marginLeft: 50, fontSize: 40 }}>{this.state.featureStats}</h1>
                                    </Card>
                                </div>

                            </Dialog>
                            : null
                    }
                    <Alert
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={this.state.isDelete}
                        loading={this.state.isLoading}
                        onCancel={this.handleDeleteCancel}
                        onConfirm={this._handleDeleteConfirm}
                    >
                        <p>
                            Are you sure you want to Delete ?
                        </p>
                    </Alert>
                    {this.state.status === "IN-ACTIVE" ?
                        <Alert
                            className=""
                            cancelButtonText="Cancel"

                            confirmButtonText="Deactive"
                            icon="ban-circle"
                            intent={Intent.WARNING}
                            isOpen={this.state.isOpen}
                            loading={this.state.isLoading}
                            onCancel={this.handleMoveCancel}
                            onConfirm={this.handleMoveActivationConfirm}
                        >
                            <p>
                                Are you sure you want to Deactive ?
                            </p>
                        </Alert>
                        :
                        <Alert
                            className=""
                            cancelButtonText="Cancel"

                            confirmButtonText="Active"
                            icon="ban-circle"
                            intent={Intent.SUCCESS}
                            isOpen={this.state.isOpen}
                            loading={this.state.isLoading}
                            onCancel={this.handleMoveCancel}
                            onConfirm={this.handleMoveActivationConfirm}
                        >
                            <p>
                                Are you sure you want to Active ?
                            </p>
                        </Alert>
                    }
                </Container>



            </Header>
        )
    }
}

const mapPropsToState = (store) => {

    return {
        accesstoken: store.authReducer.accesstoken

    }
}

export default withRouter(connect(mapPropsToState)(Feature))