

// export let baseurl = "https://staging-backend.epsumthings.com";
export let baseurl = "http://185.190.143.5:8000";
// export let baseurl = "https://brown-jellyfish-42.loca.lt";
export let MQTT_URL = "mqtt://staging-backend.epsumthings.com:1883";

console.log(process.env)
if (process.env.REACT_APP_ENV === "staging") {
    baseurl = "https://staging-backend.epsumthings.com";
    // baseurl = "https://brown-jellyfish-42.loca.lt";

    MQTT_URL = "mqtt://staging-backend.epsumthings.com:1883";
    console.log("Staging environment")
} else if (process.env.REACT_APP_ENV === "local") {
    console.log("Local environment")
    baseurl = "http://localhost:8002";
    MQTT_URL = "mqtt://localhost:1883";

} else if (process.env.NODE_ENV === "production" || process.env.REACT_APP_ENV === "production") {
    baseurl = "https://backend.epsumthings.com";
    // baseurl = "https://brown-jellyfish-42.loca.lt";

    MQTT_URL = "mqtt://epsumthings.com:1883";
    console.log("Production environment")
}


export const MQTT_CLIENT_ID = 'EPSUMTHINGS_UI';


export const RESPONSE_TYPE = {
    JSON: 'json',
    NULL: null,
    BLOB: 'blob'
}

export const DEFAULT_DATA = {
    count: 0,
    next: null,
    previous: null,
    results: []
}


export const RESPONSE_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
}
export function apiErrorHandler(response, errorCallBack) {
    switch (response.status) {
        case RESPONSE_CODE.INTERNAL_SERVER_ERROR:
            return response.statusText;
        default:
            getApiErrorMessage(response, errorCallBack);
            break;
    }
}


function getApiErrorMessage(response, errorCallBack) {
    (response.json()).then((data) => {
        let key = Object.keys(data)
        key = key[0]
        if (typeof data[key] === 'string')
            return errorCallBack(data[key])
        if (data.length > 0) {
            data = data[0]
        }
        let keys = Object.keys(data)
        if (keys.length > 0) {
            keys = keys[0]
        }
        return errorCallBack(data[keys][0])
    })
}


/**
 * fetchApi calls the api and communcates data using callbacks. Returns null when get request has a body
 * 
 * @param {string} url - URL to which fetch api 
 * @param {string} headers - Headers
 * @param {string} method - get | post | put | delete 
 * @param {object} body - API call using body
 * @param {function} successCallback - Callback upon successful api fetch
 * @param {function} failureCallback - Callback upon failed api fetch
 * @param {function} errorCallback - Callback upon fetch exception
 * @param {*} responseType 
 */
const fetchApi = (url, headers, method, body = null, successCallback, failureCallback, errorCallback, responseType = RESPONSE_TYPE.JSON) => {
    let options = null;

    if (method === "get" && body !== null) {
        errorCallback("GET request does not support body")
        return null
    } else if (method === "get") {

        options = {

            method: method,
            headers: headers
        }
    } else {
        options = {
            method: method,
            body: JSON.stringify(body),
            headers: headers
        }
    }
    fetch(url, options)
        .then(response => {
            if (response.status >= 400) {
                return response
            } else {
                switch (responseType) {
                    case RESPONSE_TYPE.JSON:
                        return response.json();
                    case RESPONSE_TYPE.BLOB:
                        return response.blob();
                    case RESPONSE_TYPE.NULL:
                        return DEFAULT_DATA
                    default:
                        return
                }
            }
        })
        .then(responseJson => {
            if (responseJson.type === 'cors') {
                apiErrorHandler(responseJson, errorCallback)
            } else {
                if (responseJson.status === "success") {
                    successCallback(responseJson)
                } else {
                    failureCallback(responseJson)
                }
            }
        }).catch(error => {
            errorCallback(error)
        })

}

export const authenticateAdmin = (username, password, successCallback, failureCallback, errorCallback) => {
    const token = btoa(username + ":" + btoa(password))
    fetchApi(`${baseurl}/admin/auth/`, {
        "Authorization": `Authenticate ${token}`
    }, 'post', null, successCallback, failureCallback, errorCallback)

}


export const createFeature = (featureDetails, token, successCallback, failureCallback, errorCallback) => {
    const { name,
        feature_request_map,
        cost_per_unit,
        currency_unit,
        unit_type,
    } = featureDetails
    const body = {
        name,
        feature_request_map,
        cost_per_unit,
        currency_unit,
        unit_type
    }
    fetchApi(`${baseurl}/billing/feature/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)
}

export const addFeatureToPackage = (featureDetails, token, successCallback, failureCallback, errorCallback) => {

    const {
        package_id,
        feature_id,
        allowed_unit

    } = featureDetails
    const body = {
        package_id,
        feature_id,
        allowed_unit
    }
    fetchApi(`${baseurl}/billing/package_features/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)
}


export const getFeature = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/feature/all`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const updateFeature = (feature_id, name, feature_request_map,
    cost_per_unit,
    currency_unit,
    unit_type, token, successCallback, failureCallback, errorCallback) => {
    cost_per_unit = parseFloat(cost_per_unit)
    const body = {
        feature_id, name, feature_request_map,
        cost_per_unit,
        currency_unit,
        unit_type,
    }
    fetchApi(`${baseurl}/billing/feature/`, { Authorization: `Bearer ${token}` }, 'put', body, successCallback, failureCallback, errorCallback)
}

export const deleteFeature = (feature_id, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        feature_id
    }
    fetchApi(`${baseurl}/billing/feature/`, { Authorization: `Bearer ${token}` }, 'delete', body, successCallback, failureCallback, errorCallback)
}

export const searchFeature = (search, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/feature/?search=${search}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const createBillingPage = (billingDetails, token, successCallback, failureCallback, errorCallback) => {
    const { name,
        cost,
        currency_unit,
        validity,
    } = billingDetails
    const body = {
        name,
        cost,
        currency_unit,
        validity
    }
    fetchApi(`${baseurl}/billing/package/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)
}


export const getBillingPage = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/package/all/`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}


export const getActivePackage = (status, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/package/?status=${status}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}
export const getInActivePackage = (status, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/package/?status=${status}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const getActiveFeature = (status, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/feature/?status=${status}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}
export const getInActiveFeature = (status, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/feature/?status=${status}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const updateBillingPage = (package_id, name, cost, currency_unit, validity, token, successCallback, failureCallback, errorCallback) => {
    cost = parseFloat(cost)
    const body = { package_id, name, cost, currency_unit, validity }
    fetchApi(`${baseurl}/billing/package/`, { Authorization: `Bearer ${token}` }, 'put', body, successCallback, failureCallback, errorCallback)
}

export const deleteBillingPage = (package_id, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        package_id
    }
    fetchApi(`${baseurl}/billing/package/`, { Authorization: `Bearer ${token}` }, 'delete', body, successCallback, failureCallback, errorCallback)
}

export const searchPackage = (search, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/package/all/?search=${search}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}



export const createOrganisation = (orgDetails, token, successCallback, failureCallback, errorCallback) => {
    const { org_name,
        org_logo_path,
        org_phone,
        org_email,
        org_type,
        org_desc,
        org_website
    } = orgDetails
    const body = {
        org_name,
        org_logo_path,
        org_phone,
        org_email,
        org_type,
        org_desc,
        org_website
    }
    fetchApi(`${baseurl}/admin/organisation/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)
}


export const getOrganisations = (pageNumber, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/organisation/`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)

}
export const updateOrganisation = (org_id, org_name, org_logo_path, org_phone,
    org_email, org_type, org_desc, org_website, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        org_id,
        org_name,
        org_logo_path,
        org_phone,
        org_email,
        org_type,
        org_desc,
        org_website
    }
    fetchApi(`${baseurl}/organisation/`, { Authorization: `Bearer ${token}` }, 'put', body, successCallback, failureCallback, errorCallback)
}

export const deleteOrganisation = (org_id, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        org_id
    }
    fetchApi(`${baseurl}/organisation/`, { Authorization: `Bearer ${token}` }, 'delete', body, successCallback, failureCallback, errorCallback)
}



export const blockOrganisation = (org_id, org_block, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        org_id,
        org_block
    }
    fetchApi(`${baseurl}/admin/organisation/`, { Authorization: `Bearer ${token}` }, 'put', body, successCallback, failureCallback, errorCallback)
}
export const getOrganisationDetails = (token, id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/organisation/?org_id=${id}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)

}


export const getAdminDetails = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/users/`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)

}
export const searchAdmins = (search, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/users/?search=${search}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const createAdmin = (adminDetails, token, successCallback, failureCallback, errorCallback) => {
    const { name,
        email,
        phone,
        password
    } = adminDetails
    const body = {
        name,
        email,
        phone,
        password
    }
    fetchApi(`${baseurl}/admin/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)
}

export const createOrgUser = (token, userDetails, successCallback, failureCallback, errorCallback) => {
    const { org_id, name,
        email,
        phone,
        timezone,
        country,
        password
    } = userDetails
    const body = {
        org_id,
        user: {
            name,
            email,
            phone,
            timezone,
            country,
            password
        }

    }
    fetchApi(`${baseurl}/admin/organisation/user/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)
}
export const getAdminOrganisationUser = (token, id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/organisation/user/?org_id=${id}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const getProfileDetails = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/profile/`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)

}

export const updateProfileDetails = (name, phone, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        name,
        phone,
    }
    fetchApi(`${baseurl}/admin/profile/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)
}

export const changePassword = (oldpassword, newpassword, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        old_password: btoa(oldpassword),
        password: btoa(newpassword),
    }
    fetchApi(`${baseurl}/admin/profile/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)

}

export const getStats = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/stats/`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}
export const getUsersList = (pageNumber, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/user/?page=${pageNumber}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const getUsersOrgList = (org_id, pageNumber, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/organisation/user/?org_id=${org_id}&page=${pageNumber}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const deleteUserList = (admin_id, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        admin_id
    }
    fetchApi(`${baseurl}/admin/users/`, { Authorization: `Bearer ${token}` }, 'delete', body, successCallback, failureCallback, errorCallback)
}

export const updateUser = (user_id, email, name, phone, country, timezone, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        user_id,
        email,
        name,
        phone,
        country,
        timezone
    }
    fetchApi(`${baseurl}/admin/user/profile/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)
}

export const blockUser = (user_id, status, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        "user_id": user_id,
        "status": status
    }
    fetchApi(`${baseurl}/admin/user/`, { Authorization: `Bearer ${token}` }, 'put', body, successCallback, failureCallback, errorCallback)
}


export const packageActivation = (package_id, status, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        "package_id": package_id,
        "status": status
    }
    fetchApi(`${baseurl}/billing/package/stats/`, { Authorization: `Bearer ${token}` }, 'put', body, successCallback, failureCallback, errorCallback)
}

export const featureActivation = (feature_id, status, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        "feature_id": feature_id,
        "status": status
    }
    fetchApi(`${baseurl}/billing/feature/stats/`, { Authorization: `Bearer ${token}` }, 'put', body, successCallback, failureCallback, errorCallback)
}

export const resetPassword = (user_id, new_password, confirm_password, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        user_id,
        new_password: btoa(new_password),
        confirm_password: btoa(confirm_password),
    }
    fetchApi(`${baseurl}/admin/user/profile/`, { Authorization: `Bearer ${token}` }, 'put', body, successCallback, failureCallback, errorCallback)
}

export const searchUsersList = (search, token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/admin/user/?search=${search}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const packageList = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/package/all/`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const featureList = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/feature/all/`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}


export const getPackageFeature = (token, package_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/package_features/?package_id=${package_id}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}


export const deletePackageFeature = (package_features_id, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        package_features_id
    }
    fetchApi(`${baseurl}/billing/package_features/`, { Authorization: `Bearer ${token}` }, 'delete', body, successCallback, failureCallback, errorCallback)
}

export const getPackageStats = (token, package_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/package/stats/?package_id=${package_id}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const getFeatureStats = (token, feature_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/feature/stats/?feature_id=${feature_id}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}
export const getUserSubscriptions = (token, user_id, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/billing/subscription/user/?user_id=${user_id}`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}

export const deleteAdmin = (admin_id, token, successCallback, failureCallback, errorCallback) => {
    const body = {
        admin_id
    }
    fetchApi(`${baseurl}/admin/users/`, { Authorization: `Bearer ${token}` }, 'delete', body, successCallback, failureCallback, errorCallback)
}
export const getInformations = (token, successCallback, failureCallback, errorCallback) => {
    fetchApi(`${baseurl}/cms/`, { Authorization: `Bearer ${token}` }, 'get', null, successCallback, failureCallback, errorCallback)
}
export const createInformation = (token, notification, successCallback, failureCallback, errorCallback) => {
    const body = { notification }
    fetchApi(`${baseurl}/cms/info/`, { Authorization: `Bearer ${token}` }, 'post', body, successCallback, failureCallback, errorCallback)
}
export const deleteInformation = (token, info_id, successCallback, failureCallback, errorCallback) => {
    const body = { info_id }
    fetchApi(`${baseurl}/cms/info/`, { Authorization: `Bearer ${token}` }, 'delete', body, successCallback, failureCallback, errorCallback)
}