import { LOGIN, LOGOUT } from "./actionTypes";

export function loginAction(accesstoken){
    return {
        type:LOGIN,
        accesstoken
    }
}

export function logoutAction(){
    return {
        type:LOGOUT
    }
}