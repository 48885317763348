import React from "react";

import {
  Button,
  Classes,
  Drawer,
  Position,
  Menu,
  MenuItem,
  NavbarGroup,
  NavbarHeading,
  NavbarDivider,
  Navbar,
  Alignment,
  Intent,
  Divider,
  Card,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { Omnibar } from "@blueprintjs/select";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutAction } from "../authentication/actions";


class Header extends React.Component {

  state = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    hasBackdrop: true,
    isOpen: false,
    position: Position.RIGHT,
    size: undefined,
    usePortal: true,
    searchbarOpen: false,
    redirect: false,
    link: ''
  };

  _renderUserMenu() {
    return (
      <Menu position={Position.BOTTOM}>
        <MenuItem icon="user" text="Profile" onClick={() => this._navigateTo('/profile')} />
        <Divider />
        <MenuItem onClick={this._logout} text="Logout" icon="log-out" />
      </Menu>
    )
  }


  _renderNotificationsMenu() {
    return (
      <Menu >
        <MenuItem disabled text="" />

        <MenuItem disabled text="No notifications to show" />
        <MenuItem disabled text="" />
        {/* <MenuItem text="Child three" /> */}
      </Menu>
    )
  }


  _renderOmnibar() {
    return (
      <div style={{ marginTop: 100, width: "100%" }}>
        <Omnibar
          allowCreate={true}
          isOpen={this.state.searchbarOpen}
          noResults={<MenuItem disabled={true} text="No results." />}
          items={[]}
          onClose={(event) => { this.setState({ searchbarOpen: false }) }}

        />
      </div>
    )
  }

  _isActive() {
    return
  }

  _navigateTo(link) {
    this.props.history.push(link)
    // this.setState({ redirect: true, link: link })
  }

  _searchbarHandler = () => { const { searchbarOpen } = this.state; this.setState({ searchbarOpen: !searchbarOpen }) }

  _logout = () => {
    this.props.dispatch(logoutAction())
    this._navigateTo('/')
    console.log("logout")
  }

  render() {
    return (
      <div >
        {this.state.redirect ?
          <Redirect to={this.state.link} /> : null
        }
        <Card color="dark" className="row">
          <div className="col" style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
            <Button className={Classes.MINIMAL} style={{ marginRight: 4 }} icon="menu" onClick={this.handleOpen} />
            <NavbarHeading>EpsumThings</NavbarHeading>
            <NavbarDivider />
          </div>
          <div className="col-2" style={{ display: "flex", justifyContent: "right" }}>
            {/* <Button className={Classes.MINIMAL} onClick={this._searchbarHandler} icon="search" /> */}
            <Popover2 content={this._renderNotificationsMenu()}>
              <Button className={Classes.MINIMAL} icon="notifications" />
            </Popover2>
            <Popover2 content={this._renderUserMenu()} position={Position.BOTTOM}>
              <Button intent={Intent.PRIMARY} className={Classes.MINIMAL} icon="user" />
            </Popover2>
          </div>
        </Card>

        <Drawer
          className={"default"}
          onClose={this.handleClose}
          title=" Administration"
          {...this.state}
          position={Position.LEFT}
          size={300}
        >

          <div className={Classes.DRAWER_BODY}>
            <Menu>
              <MenuItem text="Dashboard"
                icon="dashboard"
                onClick={() => this._navigateTo('/dashboard')} />

              <MenuItem icon="office"
                onClick={() => this._navigateTo('/org')}
                text="Organisations" />
              <MenuItem icon="learning" text="Legal" />
              <MenuItem icon="info-sign"
                onClick={() => this._navigateTo('/info')}
                text="Information" />
              <MenuItem icon="user"
                onClick={() => this._navigateTo('/profile')}
                text="Profile" />
              <MenuItem icon="person"
                onClick={() => this._navigateTo('/admin')}
                text="Admin" />
              <MenuItem icon="people"
                onClick={() => this._navigateTo('/users')}
                text="Users" />

              <MenuItem icon="euro" text="Billing" >

                <MenuItem icon="bank-account"
                  onClick={() => this._navigateTo('/packages')}
                  text="Packages" />
                <MenuItem icon="new-grid-item"
                  onClick={() => this._navigateTo('/feature')}
                  text="Feature" />

              </MenuItem>

            </Menu>
          </div>
          <div className={Classes.DRAWER_FOOTER}>
            <Menu>
              <MenuItem onClick={this._logout} text="Logout" icon="log-out" />
            </Menu>
          </div>
          <div className={Classes.DRAWER_FOOTER}>Copyright &copy; Epsum Labs Private Limited</div>
        </Drawer>
        {/* {this._renderOmnibar()} */}
        {this.props.children}

      </div >
    );
  }



  handleOpen = () => this.setState({ isOpen: true });

  handleClose = () => this.setState({ isOpen: false });
}


// const mapPropsToState = (store) => {
//     console.log(store)
//     return {

//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {


//     }
// }


export default withRouter(connect()(Header))
