import { Button, ButtonGroup, Classes, Dialog, FormGroup, InputGroup, Toast, Toaster, Alert, Intent, Card } from '@blueprintjs/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Container from '../../layouts/Container'
import Header from '../../layouts/Header'
import { Tooltip2 } from '@blueprintjs/popover2'
import { getAdminDetails, createAdmin, searchAdmins, deleteAdmin } from '../../../library/api'
import { validateEmail, validateName, validatePassword } from '../../../library/utils'

class Admin extends Component {
    state = {
        toasts: [],
        information: '',
        openCreateAdminDialog: false,
        admins: [],
        name: '',
        email: '',
        phone: '',
        password: '',
        search: '',
        isDelete: false,
    }

    toaster;
    refHandlers = {
        toaster: (ref) => this.toaster = ref,
    };

    componentDidMount() {
        const toaster = this.toaster
        getAdminDetails(this.props.accesstoken,
            (success) => {
                this.setState({ admins: success.data })
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            }, (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    createAdmin = () => {
        this.setState({ openCreateAdminDialog: true })
    }
    _handleCreate = () => {
        const toaster = this.toaster;
        if (this.state.name.trim() !== "" && this.state.email !== "" && this.state.phone !== "" && this.state.password !== "") {
            var nameValid = validateName(this.state.name)
            var emailValid = validateEmail(this.state.email)
            var passwordValid = validatePassword(this.state.password)
            if (nameValid == "false") {
                toaster.show({ message: "Name contains only Alphabets", intent: 'danger' });
            }
            else if (emailValid == false) {
                toaster.show({ message: "Email Is not formated", intent: 'danger' });
            }
            else if (this.state.phone.length <= 9) {
                toaster.show({ message: "Enter Correct Phone number", intent: 'danger' });
            }
            else if (passwordValid == false) {
                toaster.show({ message: "Password must contain at least 8 characters, including UPPER/lowercase , numbers and Special character .", intent: 'danger' });
            }
            else {
                this.setState({ openCreateAdminDialog: false })
                createAdmin(this.state, this.props.accesstoken,
                    (success) => {
                        toaster.show({ message: success.msg, intent: 'success' });
                        this.setState({
                            name: '',
                            email: '',
                            phone: '',
                            password: ''
                        })
                        this.componentDidMount()
                    },
                    (failure) => {
                        toaster.show({ message: failure.msg, intent: 'danger' });
                    },
                    (error) => {
                        toaster.show({ message: String(error), intent: 'danger' });
                    })
            }
        }
        else {
            toaster.show({ message: "Field Can't Be Blank", intent: 'danger' });
        }

    }


    _renderCreateAdminDialog() {
        return (
            <Dialog
                canEscapeKeyClose={true}
                onClose={() => this.setState({
                    openCreateAdminDialog: false,
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                })}
                title="Create Admin"
                isOpen={this.state.openCreateAdminDialog}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div>
                        <FormGroup
                            helperText="Name "
                            label="Name"
                            labelFor="name"
                            labelInfo="(required)"
                        >
                            <InputGroup type='text' pattern="[A-Za-z]" onChange={(event) => this.setState({ name: event.target.value })} required leftIcon="office" id="name" placeholder="Abcd" />
                        </FormGroup>
                        <FormGroup
                            helperText="Email"
                            label="Email"
                            labelFor="email"
                            labelInfo="(required)"
                        >
                            <InputGroup leftIcon="envelope" id="email" type="email" onChange={(event) => this.setState({ email: event.target.value })} required placeholder="abc@gmail.com" />
                        </FormGroup>
                        <FormGroup
                            helperText="Contact number"
                            label="Phone number"
                            labelFor="phone"
                            labelInfo="(required)"
                            style={{ marginRight: 10 }}
                        >
                            <InputGroup leftIcon="phone" id="phone" onChange={(event) => this.setState({ phone: event.target.value })} required type="number" placeholder="+91xxxxxxxxxx" />
                        </FormGroup>
                        <FormGroup
                            helperText="Password"
                            label="Password"
                            labelFor="password"
                            labelInfo="(required)"
                        >
                            <InputGroup leftIcon="envelope" id="password" type="password" onChange={(event) => this.setState({ password: event.target.value })} required placeholder="password" />
                        </FormGroup>
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Tooltip2 content="Close organisation creation dialog">
                            <Button onClick={this._handleAdminDialogClose}>Close</Button>
                        </Tooltip2>
                        <Tooltip2 content="Click here to create Admin">
                            <Button intent="primary" onClick={this._handleCreate}>Create</Button>
                        </Tooltip2>
                    </div>
                </div>
            </Dialog>
        )
    }

    _handleAdminDialogClose = () => {
        this.setState({
            openCreateAdminDialog: false,
            name: '',
            email: '',
            phone: '',
            password: '',
        })
    }

    _handleSearch = () => {

        searchAdmins(this.state.search, this.props.accesstoken,
            (success) => {
                this.setState({ admins: success.data })
            },
            (failure) => {
                alert(failure.msg)

            }, (error) => {
                alert(String(error))

            })
    }

    removeAdmin = (value) => {
        this.setState({
            isDelete: true,
            admin_id: value.admin_id,
        });
    }

    handleDeleteCancel = () => this.setState({ isDelete: false });

    _handleDeleteConfirm = () => {
        const toaster = this.toaster;
        this.setState({
            isDelete: false,
        });
        deleteAdmin(this.state.admin_id, this.props.accesstoken,
            (success) => {
                this.componentDidMount()
                toaster.show({ message: success.msg, intent: 'success' });
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });
            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    render() {
        return (
            <Header>
                <Toaster {...{ position: 'top-right' }} ref={this.refHandlers.toaster}>
                    {this.state.toasts.map(toast => <Toast {...toast} />)}
                </Toaster>
                {this._renderCreateAdminDialog()}
                <div className='row justify-content-end' style={{ width: '100%', padding: 10 }}>
                    <div className='col-lg-5 col-md-6 col-sm row' style={{ display: "flex", justifyContent: "center" }}>
                        <FormGroup className='col-sm' >
                            <div class="bp3-input-group bp3-large">
                                <span class="bp3-icon bp3-icon-search"></span>
                                <input class="bp3-input" type="search" placeholder="Search Admin" dir="auto" onChange={(event) =>
                                    this.setState({ search: event.target.value })} />
                            </div>
                        </FormGroup>
                        <Button className='col-sm' intent="primary" onClick={this._handleSearch} type="submit" style={{ margin: 15, marginLeft: 0, marginTop: 0, padding: 0, paddingTop: 0, paddingBottom: 0, width: "150px", fontSize: 16 }}>Search</Button>
                        <Button className='col-sm d-flex justify-content-center' onClick={this.createAdmin} icon="add" intent="success" style={{ margin: 15, marginLeft: 0, marginTop: 0, padding: 0, paddingTop: 0, paddingBottom: 0, width: "150px", fontSize: 16 }} >Create Admin</Button>
                    </div>
                </div>
                <h2 className='ms-2 text-sm-left '>Admin</h2>
                <div style={{ justifyContent: 'center', marginInline: "2%" }}>
                    <Card style={{ width: "100%", overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
                        <table style={{ width: "100%" }} className="bp3-html-table bp3-interactive .modifier">
                            <thead>
                                <tr>
                                    <th>Sl No.</th>
                                    <th>Name</th>
                                    <th> Email</th>
                                    <th>Phone no</th>
                                    <th>Last Activity</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {this.state.admins.users != undefined ?
                                <tbody>
                                    {this.state.admins.users.map((value, index) =>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{value.name}</td>
                                            <td>{value.email}</td>
                                            <td>{value.phone}</td>
                                            <td>{value.last_active} </td>
                                            <td>
                                                <Button icon="trash" text="Delete" intent="danger" onClick={() => this.removeAdmin(value)} />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                :
                                <tbody />
                            }
                            <tfoot>
                                <tr>
                                </tr>
                            </tfoot>
                        </table>
                    </Card>

                    <Alert
                        className=""
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={this.state.isDelete}
                        onCancel={this.handleDeleteCancel}
                        onConfirm={this._handleDeleteConfirm}
                    >
                        <p>
                            Are you sure you want to Delete ?
                        </p>
                    </Alert>
                </div>

            </Header >
        )
    }
}


const mapPropsToState = (store) => {

    return {
        accesstoken: store.authReducer.accesstoken
    }
}
export default withRouter(connect(mapPropsToState)(Admin))
