import { LOGIN, LOGOUT } from "./actionTypes";

const INTIAL_AUTHENTICATION_STATE = {
    accesstoken: null,
    username: null,
    fullname: null
}

export default function authenticationReducer(state = INTIAL_AUTHENTICATION_STATE, action) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                accesstoken: action.accesstoken
            }
        case LOGOUT:
            return {
                ...INTIAL_AUTHENTICATION_STATE
            }

        default:
            return state
    }
}