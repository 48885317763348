import { Button, ButtonGroup, Classes, Dialog, FormGroup, InputGroup, Menu, MenuItem, Position, Toast, Toaster, Alert, Intent } from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getUsersList, resetPassword, updateUser, deleteUserList, blockUser, searchUsersList, } from '../../../library/api'
import Container from '../../layouts/Container'
import Header from '../../layouts/Header'
import DataTable from '../../../Components/table/DataTable';
import { validateEmail, validateName, validatePassword } from '../../../library/utils'

class Users extends Component {
    state = {
        toasts: [],
        usersList: [],
        isOpen: false,
        isDelete: false,
        openEditUserDialog: false,
        openPasswordDialog: false,
        user_id: '',
        name: '',
        phone: '',
        email: '',
        country: '',
        timezone: '',
        status: "",
        confirm_password: '',
        new_password: '',
        search: '',
        userPage: '',
        currentPage: 1,
        pageNumber: 1,
        userPage: {
            count: 0
        },
    }
    toaster;
    refHandlers = {
        toaster: (ref) => this.toaster = ref,
    };

    componentDidMount() {
        const toaster = this.toaster
        getUsersList(this.state.pageNumber, this.props.accesstoken,
            (success) => {
                this.setState({
                    userPage: success,
                    usersList: success.data
                })
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            }, (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    _renderActionMenu(value) {
        return (
            <Menu>
                <MenuItem intent="success" icon="edit" text="Edit" onClick={() => this.editUser(value)} />
                {value.user_status === false ?
                    <MenuItem intent="warning" icon="ban-circle" text="Block" onClick={() => this.handleMoveOpen(value)} />
                    :
                    <MenuItem intent="warning" icon="ban-circle" text="Unblock" onClick={() => this.handleMoveOpen(value)} />
                }
                {/* <MenuItem intent="danger" icon="trash" text="Delete" onClick={() => this.handleMoveDelete(value)} /> */}

                <MenuItem icon="share" text="Subscriptions" onClick={() => this._navigateTo(`/userSubscriptions/${value.user_id}`)} />

                <MenuItem intent="primary" icon="key" text="Reset Password" onClick={() => this.handleResetPassword(value)} />
            </Menu>
        )
    }



    handleResetPassword = (value) => {
        this.setState({
            openPasswordDialog: true,
            user_id: value.user_id,
        })
    }

    _handlePasswordClose = () => {
        this.setState({
            openPasswordDialog: false,
        })
    }
    _handlePasswordUpdate = () => {
        this.setState({
            openPasswordDialog: false,
        })
        const toaster = this.toaster;
        resetPassword(this.state.user_id, this.state.new_password, this.state.confirm_password,
            this.props.accesstoken,
            (success) => {
                toaster.show({ message: success.msg, intent: 'success' });
                this.componentDidMount()
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });
            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }


    _handleUserDialogClose = () => {
        this.setState({
            openEditUserDialog: false
        })
    }


    editUser = (value) => {
        this.setState({
            openEditUserDialog: true,
            user_id: value.user_id,
            name: value.name,
            phone: value.phone,
            email: value.email,
            country: value.country,
            timezone: value.timezone,
        })

    }

    _handleUpdate = () => {
        const toaster = this.toaster;
        if (this.state.name.trim() !== "" && this.state.email !== "" && this.state.phone !== "") {
            var nameValid = validateName(this.state.name)
            var emailValid = validateEmail(this.state.email)
            if (nameValid == "false") {
                toaster.show({ message: "Name contains only Alphabets", intent: 'danger' });
            }
            else if (emailValid == false) {
                toaster.show({ message: "Email Is not formated", intent: 'danger' });
            }
            else if (this.state.phone.length <= 9) {
                toaster.show({ message: "Enter Correct Phone number", intent: 'danger' });
            }
            else {
                this.setState({
                    openEditUserDialog: false,
                })
                updateUser(this.state.user_id, this.state.email, this.state.name,
                    this.state.phone, this.state.country, this.state.timezone
                    , this.props.accesstoken,
                    (success) => {
                        toaster.show({ message: success.msg, intent: 'success' });
                        this.componentDidMount()
                    },
                    (failure) => {
                        toaster.show({ message: failure.msg, intent: 'danger' });
                    },
                    (error) => {
                        toaster.show({ message: String(error), intent: 'danger' });
                    })
            }
        }
        else {
            toaster.show({ message: "Field Can't Be Blank", intent: 'danger' });
        }


    }

    handleMoveOpen = (value) => {
        this.setState({
            isOpen: true,
            user_id: value.user_id,
            status: value.user_status

        });
        if (value.user_status === false) {
            this.setState({
                status: true
            })
        }
        else {
            this.setState({
                status: false
            })
        }
    }

    handleMoveCancel = () => this.setState({ isOpen: false });

    handleMoveBlockConfirm = () => {
        const toaster = this.toaster;
        blockUser(this.state.user_id, this.state.status, this.props.accesstoken,
            (success) => {
                toaster.show({ message: this.state.status == true ? "User Blocked" : "User Unblocked", intent: 'success' });
                this.componentDidMount()
                this.setState({
                    isOpen: false,
                });
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });
            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    handleMoveDelete = (value) => {
        this.setState({
            isDelete: true,
            user_id: value.user_id,
        });
    }

    handleMoveConfirm = () => {
        this.setState({
            isDelete: false,
        });
        const toaster = this.toaster;
        deleteUserList(this.state.user_id, this.props.accesstoken,
            (success) => {
                toaster.show({ message: success.msg, intent: 'success' });
                this.componentDidMount()
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });
            },
            (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    handleMoveDeleteCancel = () => this.setState({ isDelete: false });
    _navigateTo(link) {
        this.props.history.push(link)
    }

    _handleSearch = () => {
        const toaster = this.toaster
        searchUsersList(this.state.search, this.props.accesstoken,
            (success) => {
                this.setState({
                    usersList: success.data,
                    userPage: success,
                })
            },
            (failure) => {
                toaster.show({ message: failure.msg, intent: 'danger' });

            }, (error) => {
                toaster.show({ message: String(error), intent: 'danger' });
            })
    }

    getPageCount = () => {
        return Math.ceil(parseInt(this.state.userPage.count) / parseInt(10));
    }
    handlePageChange(pageIndex) {
        let pageNumber = pageIndex === 0 ? 1 : pageIndex;
        this.setState({ currentPage: pageNumber });
        // this.props.onPageChange(pageNumber)
    }

    _renderTable = () => {

        const toaster = this.toaster
        return (
            <DataTable
                totalItemsCount={this.state.userPage.count}
                itemList={this.state.usersList.length}

                onPageChange={(pageNumber) => {
                    getUsersList(pageNumber, this.props.accesstoken,
                        (success) => {
                            this.setState({
                                userPage: success,
                                usersList: success.data
                            })
                        },
                        (failure) => {
                            toaster.show({ message: failure.msg, intent: 'danger' });

                        }, (error) => {
                            toaster.show({ message: String(error), intent: 'danger' });
                        })
                }}
                headers={["Sl No.", "Name", "Phone", "Email", "Timezone", "Action"]}
                body={this.state.usersList.map((value, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value.name}</td>
                        <td>{value.phone}</td>
                        <td>{value.email}</td>
                        <td>{value.timezone}</td>
                        <td>
                            <Popover2 content={this._renderActionMenu(value)}
                                position={Position.BOTTOM}>
                                <Button icon="cog" text="Action" intent="primary" rightIcon={"caret-down"} />
                            </Popover2>
                        </td>
                    </tr>
                ))}
            />
        )



    }




    render() {
        return (
            <Header>
                <Toaster {...{ position: 'top-right' }} ref={this.refHandlers.toaster}>
                    {this.state.toasts.map(toast => <Toast {...toast} />)}
                </Toaster>
                <div style={{ width: '100%', padding: 10 }}>
                    <div style={{ float: 'right', display: 'flex', flexDirection: 'row' }}>
                        <FormGroup>
                            <div class="bp3-input-group bp3-large">
                                <span class="bp3-icon bp3-icon-search"></span>
                                <input class="bp3-input" type="search" placeholder="Search User" dir="auto" onChange={(event) =>
                                    this.setState({ search: event.target.value })} />
                            </div>
                        </FormGroup>
                        <Tooltip2 content="Click here to search ">
                            <Button intent="primary" onClick={this._handleSearch} type="submit" style={{ margin: 10, marginTop: 0, padding: 20, paddingTop: 10, paddingBottom: 10, fontSize: 16 }}>Search</Button>
                        </Tooltip2>
                    </div>
                </div>
                <Container style={{ paddingBottom: 0 }}>
                    <h2>Users</h2>
                </Container>
                <Container style={{ justifyContent: 'center' }}>


                    {this.state.usersList.length === 0 ? <center style={{ marginTop: 30 }}>No Users registered yet. </center> : this._renderTable()}

                    <Dialog
                        canEscapeKeyClose={true}
                        onClose={() => this.setState({ openEditUserDialog: false })}
                        title="Edit User"
                        isOpen={this.state.openEditUserDialog}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <div>
                                <FormGroup
                                    helperText="Name of the User"
                                    label="User name"
                                    labelFor="name"
                                    labelInfo="(required)"
                                >
                                    <InputGroup onChange={(event) => this.setState({ name: event.target.value })} required leftIcon="user" placeholder="Name" value={this.state.name} />
                                </FormGroup>
                                <FormGroup
                                    helperText="Contact number"
                                    label="Phone number"
                                    labelFor="phone"
                                    labelInfo="(required)"
                                    style={{ marginRight: 10 }}
                                >
                                    <InputGroup leftIcon="phone" onChange={(event) => this.setState({ phone: event.target.value })} required type="number" placeholder="+91xxxxxxxxxx"
                                        value={this.state.phone}
                                    />
                                </FormGroup>
                                <FormGroup
                                    helperText="Email address"
                                    label="Email"
                                    labelFor="email"
                                    labelInfo="(required)"
                                >
                                    <InputGroup leftIcon="envelope" type="email" onChange={(event) => this.setState({ email: event.target.value })} required placeholder="developers@epsumlabs.com"
                                        value={this.state.email}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip2 content="Close creation dialog">
                                    <Button onClick={this._handleUserDialogClose}>Close</Button>
                                </Tooltip2>
                                <Tooltip2 content="Click here to update ">
                                    <Button intent="primary" onClick={this._handleUpdate}>Update</Button>
                                </Tooltip2>

                            </div>
                        </div>
                    </Dialog>

                    <Dialog
                        canEscapeKeyClose={true}
                        onClose={() => this.setState({ openPasswordDialog: false })}
                        title="Reset Password"
                        isOpen={this.state.openPasswordDialog}
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <div>
                                <FormGroup
                                    helperText="New Password"
                                    label="New Password"
                                    labelFor="New Password"
                                    labelInfo="(required)"
                                >
                                    <InputGroup onChange={(event) => this.setState({ new_password: event.target.value })} required leftIcon="key" placeholder="New Password" value={this.state.new_password} />
                                </FormGroup>
                                <FormGroup
                                    helperText="Confirm Password"
                                    label="Confirm Password"
                                    labelFor="confirm password"
                                    labelInfo="(required)"
                                    style={{ marginRight: 10 }}
                                >
                                    <InputGroup leftIcon="key" onChange={(event) => this.setState({ confirm_password: event.target.value })} required type="Confirm Password" placeholder="Confirm Password"
                                        value={this.state.confirm_password}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Tooltip2 content="Close  creation dialog">
                                    <Button onClick={this._handlePasswordClose}>Close</Button>
                                </Tooltip2>
                                <Tooltip2 content="Click here to update the ">
                                    <Button intent="primary" onClick={this._handlePasswordUpdate}>Update</Button>
                                </Tooltip2>

                            </div>
                        </div>
                    </Dialog>

                    {this.state.status === true ?
                        <Alert
                            className=""
                            cancelButtonText="Cancel"

                            confirmButtonText="Block"
                            icon="ban-circle"
                            intent={Intent.WARNING}
                            isOpen={this.state.isOpen}
                            loading={this.state.isLoading}
                            onCancel={this.handleMoveCancel}
                            onConfirm={this.handleMoveBlockConfirm}
                        >
                            <p>
                                Are you sure you want to Block ?
                            </p>
                        </Alert>
                        :
                        <Alert
                            className=""
                            cancelButtonText="Cancel"

                            confirmButtonText="Unblock"
                            icon="ban-circle"
                            intent={Intent.WARNING}
                            isOpen={this.state.isOpen}
                            loading={this.state.isLoading}
                            onCancel={this.handleMoveCancel}
                            onConfirm={this.handleMoveBlockConfirm}
                        >
                            <p>
                                Are you sure you want to Unblock ?
                            </p>
                        </Alert>
                    }


                    <Alert
                        className=""
                        cancelButtonText="Cancel"
                        confirmButtonText="Delete"
                        icon="trash"
                        intent={Intent.DANGER}
                        isOpen={this.state.isDelete}
                        loading={this.state.isLoading}
                        onCancel={this.handleMoveDeleteCancel}
                        onConfirm={this.handleMoveConfirm}
                    >
                        <p>
                            Are you sure you want to Delete ?
                        </p>
                    </Alert>
                </Container>
            </Header >
        )
    }
}

const mapPropsToState = (store) => {

    return {
        accesstoken: store.authReducer.accesstoken
    }
}

export default withRouter(connect(mapPropsToState)(Users))
